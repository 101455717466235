import Client from "../client/ClientGateway"
import version from "../version"
const resource = version + "/internal/wrappers"

function gets(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}`, { params })
}
function get(id) {
  return Client("root_wrapper.wrapper_path_detail").get(`${resource}/${id}`)
}
function create(data) {
  return Client("root_wrapper.wrapper_path_create").post(`${resource}`, data)
}
function update(id, data) {
  return Client("root_wrapper.wrapper_path_update").put(`${resource}/${id}`, data)
}
function remove(id) {
  return Client("root_wrapper.wrapper_path_delete").delete(`${resource}/${id}`)
}
function getEnumsGateway() {
  return Client("root_wrapper.wrapper_path_view").get(`${version}/internal/enums`)
}

function updateMutil(data) {
  return Client("root_wrapper.wrapper_path_update").put(`${resource}/change-status/multiple`, data)
}
function removeMutil(data) {
  return Client("root_wrapper.wrapper_path_update").put(`${resource}/delete/multiple`, data)
}

function exportWrapper(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/export`, { params })
}
function importWrapper(data) {
  return Client("root_wrapper.wrapper_path_create").get(`${resource}/import`, data)
}
const api = {
  gets,
  get,
  create,
  update,
  remove,
  getEnumsGateway,
  updateMutil,
  removeMutil,
  exportWrapper,
  importWrapper
}
export default api

import Client from "../client/ClientControlPlane"
import version from "../version"
const resource = version + "/internal"
function getProject(id) {
  return Client("root_doc_testing.doc_testing_path_view").get(
    `${resource}/project-folders/${id}/all/`
  )
}
function createFolder(data) {
  return Client("root_doc_testing.doc_testing_path_create").post(
    `${resource}/project-folders/`,
    data
  )
}
function updateFolder(id, data) {
  return Client("root_doc_testing.doc_testing_path_update").put(
    `${resource}/project-folders/${id}/`,
    data
  )
}
function removeFolder(id) {
  return Client("root_doc_testing.doc_testing_path_delete").delete(
    `${resource}/project-folders/${id}/`
  )
}
function getRequest(id) {
  return Client("root_doc_testing.doc_testing_path_detail").get(
    `${resource}/project-requests/${id}/`
  )
}
function createRequest(data) {
  return Client("root_doc_testing.doc_testing_path_create").post(
    `${resource}/project-requests/`,
    data
  )
}
function updateRequest(id, data) {
  return Client("root_doc_testing.doc_testing_path_update").put(
    `${resource}/project-requests/${id}/`,
    data
  )
}
function removeRequest(id) {
  return Client("root_doc_testing.doc_testing_path_delete").delete(
    `${resource}/project-requests/${id}/`
  )
}

const api = {
  getProject,
  createFolder,
  updateFolder,
  removeFolder,
  getRequest,
  createRequest,
  updateRequest,
  removeRequest
}
export default api

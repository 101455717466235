import Client from "../client/ClientGateway"
import version from "../version"
const resource = version + "/internal/alert-rules"

function gets(params) {
  return Client("root_alert_rule.alert_rule_path_view").get(`${resource}`, { params })
}
function get(id) {
  return Client("root_alert_rule.alert_rule_path_detail").get(`${resource}/${id}`)
}
function create(data) {
  return Client("root_alert_rule.alert_rule_path_create").post(`${resource}`, data)
}
function update(id, data) {
  return Client("root_alert_rule.alert_rule_path_update").put(`${resource}/${id}`, data)
}
function updateMutil(data) {
  return Client("root_alert_rule.alert_rule_path_update").put(
    `${resource}/change-status/multiple`,
    data
  )
}
function remove(id) {
  return Client("root_alert_rule.alert_rule_path_delete").delete(`${resource}/${id}`)
}
function removeMutil(data) {
  return Client("root_alert_rule.alert_rule_path_delete").put(`${resource}/delete/multiple`, data)
}
const api = {
  gets,
  get,
  create,
  update,
  remove,
  updateMutil,
  removeMutil
}
export default api

import Client from "../client/ClientControlPlane"
import version from "../version"
const resource = version + "/internal/services"

function gets(params) {
  return Client("root_service.service_path_view").get(`${resource}/`, { params })
}
function get(id) {
  return Client("root_service.service_path_detail").get(`${resource}/${id}/`)
}
function create(data) {
  return Client("root_service.service_path_create").post(`${resource}/`, data)
}
function update(id, data) {
  return Client("root_service.service_path_update").put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client("root_service.service_path_delete").delete(`${resource}/${id}/`)
}

const api = {
  gets,
  get,
  create,
  update,
  remove
}
export default api

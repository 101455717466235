import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

export default function MyCKEditor({ onChange, data, disabled }) {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "link",
            "blockquote",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "imageUpload",
            "mediaEmbed",
            "insertTable",
            "|",
            "outdent",
            "indent",
            "|",
            "undo",
            "redo"
          ]
        },
        placeholder: "Enter content"
      }}
      data={data}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

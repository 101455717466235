import { apiUser } from "api"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useStore } from "components/ui"
import { setEnums } from "lib/localstorage"
import { getCookieAuth } from "lib/cookie"
import LoginForm from "./LoginForm"
import { ENUMS_CONFIG } from "lib/Const"
import SuspenseComponent from "components/Router/SuspenseComponent"
import { changeLanguage } from "utils/i18n"
import { convertPermission } from "lib/function"
import { apiAms } from "api"

// Functional component for handling user login
const Login = () => {
  // Destructure necessary functions and objects from the store
  const { setAuthStore } = useStore()
  const navigate = useNavigate()

  // Check if the application is running in a local environment
  const local = window.location.href.includes("local")

  // Function to initialize login process
  async function init() {
    try {
      // Retrieve access token from cookies
      const { access_token } = getCookieAuth()
      let tabId = sessionStorage.tabID

      if (!tabId) {
        tabId = Math.random()
        sessionStorage.tabID = tabId
      }

      if (access_token && tabId) {
        // If access token exists, fetch user info
        const _userInfo = apiUser.getUserInfo()

        // Fetch user permissions

        const _permission = apiUser.getPermissions()
        const [userInfo, permission] = await Promise.all([_userInfo, _permission])

        const __permission = convertPermission(permission?.data)

        // Extract organization name from user info
        const orgName = userInfo?.data?.organization?.name
        const defaultLanguage = userInfo?.data?.language || "en"

        // Build base data for local storage
        let baseData = {
          user: userInfo?.data,
          orgName: orgName,
          permission: __permission,
          access_token: access_token
        }

        // Set enums and base data to local storage
        setEnums(ENUMS_CONFIG)

        // Set authentication data in the global store
        await changeLanguage(defaultLanguage)
        // i18next.changeLanguage(language_code)
        setAuthStore(baseData)
        handleSetDataTranslateErrorMsg()
        navigate("/")
        // Navigate to the home page
      } else {
        // If no access token is found
        sessionStorage.tabID = Math.random()
        if (!local) {
          // Redirect to Single Sign-On (SSO) login page
          window.location.href = `${process.env.REACT_APP_SSO_URL}`
        }
      }
    } catch (error) {}
  }

  async function handleSetDataTranslateErrorMsg() {
    try {
      const { data } = await apiAms.getTranslateErrorMessage()
      const errorMsg =
        data &&
        data.length &&
        data.map((item) => ({
          error_code: item.error_code,
          content: item.content,
          language_code: item.language_code
        }))
      errorMsg && localStorage.setItem("errorMsg", JSON.stringify(errorMsg))
    } catch (error) {
      console.log(error)
    }
  }

  // Effect hook to initialize login process on component mount
  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Render LoginForm component in a local environment
  return <div>{local ? <LoginForm /> : <SuspenseComponent />}</div>
}

export default Login

import { useReducer, useMemo, createContext, useContext } from "react"
import {
  setLocalProject,
  getLocalProject,
  getBaseDataLocal,
  removeAuthLocal,
  setBaseDataLocal
} from "lib/localstorage"
import { removeCookies } from "lib/cookie"
import KEY from "./Const"
import { apiProjects } from "api"

function initialState() {
  let project = {},
    baseData = {}
  if (getBaseDataLocal()) {
    baseData = getBaseDataLocal()
  }
  if (getLocalProject()?.id) project = getLocalProject()
  return {
    ...baseData,
    project
  }
}

function reducer(state, action) {
  switch (action.type) {
    case KEY.SET_AUTH:
      return { ...state, ...action.value }
    case KEY.SET_PROJECT:
      localStorage.setItem("project_id", action.value?.id)
      return { ...state, project: action.value }
    case KEY.LOG_OUT:
      removeCookies()
      removeAuthLocal()
      return initialState()
    default:
      throw new Error()
  }
}

const MyContext = createContext(initialState())
MyContext.displayName = "MyContext"

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState())

  const setAuthStore = (value) => {
    const currentBaseData = getBaseDataLocal()
    const newValua = { ...currentBaseData, ...value }
    setBaseDataLocal(newValua)
    return dispatch({ type: KEY.SET_AUTH, value })
  }
  const logOut = () => {
    dispatch({ type: KEY.LOG_OUT })
  }

  const setProject = async (value) => {
    try {
      const permission = await apiProjects.getPermissions({ project_id: value.id })
      localStorage.setItem("permission", JSON.stringify(permission?.data))
    } catch (error) {}
    setLocalProject(value)
    return dispatch({ type: KEY.SET_PROJECT, value })
  }

  const value = useMemo(
    () => ({
      ...state,
      setAuthStore,
      setProject,
      logOut
    }),
    [state]
  )
  return <MyContext.Provider value={value} {...props} />
}

const useStore = () => {
  const context = useContext(MyContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export default useStore

import { useRef, useState } from "react"
import { Modal, Form, Input, notification, Button } from "antd"
import { useEffect } from "react"
import { apiProjects } from "api"
import slugify from "slugify"
import { useTranslation } from "react-i18next"
import "./index.scss"

export default function DrawerUpdate({ visible, onClose, item }) {
  const [loading, setLoading] = useState(false)
  const [slug, setSlug] = useState("")
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const { t } = useTranslation()

  async function onFinish(values) {
    try {
      setLoading(true)
      values.env_type = "development"
      await apiProjects.create(values)
      notification.success({ description: t("4068_1515") })
      setLoading(false)
      form.resetFields()
      onClose(true)
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false)
    }
  }

  function onChangeSlug(v) {
    // Update the slug based on the project name
    setSlug(slugify(v, { locale: "vi", lower: true, strict: true }))
  }

  useEffect(() => {
    // Reset slug and form fields when visibility changes
    if (!visible) {
      setSlug("")
      form.resetFields()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <Modal
      title={<div className="text-white text-xl font-medium py-4 px-8">{t("12_7865")}</div>}
      onClose={onClose}
      open={visible}
      className="modal_create_project"
      onCancel={() => onClose(true)}
      width={650}
      forceRender={true}
      onOk={() => form.submit()}
      loading={loading}
      footer={
        <div className="flex justify-end px-8 py-4 gap-4 border-t">
          <Button size="large" onClick={onClose}>
            {t("12_7875")}
          </Button>
          <Button size="large" type="primary" onClick={() => form.submit()} loading={loading}>
            {t("12_7877")}
          </Button>
        </div>
      }
    >
      <div className="py-12 px-8 pt-4">
        <div className="text-lg mb-6 font-medium">{t("12_7868")}</div>
        <Form layout="vertical" name="update_project" ref={formRef} onFinish={onFinish} form={form}>
          <Form.Item
            label={t("12_7867")}
            name="name"
            rules={[
              { required: true, message: t("4070_1516") },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || (value.trim()?.length > 0 && /^[a-zA-Z0-9 ]+$/.test(value))) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t("4070_1517")))
                }
              })
            ]}
          >
            <Input
              size="large"
              onChange={(e) => onChangeSlug(e.target.value)}
              placeholder={t("12_7870")}
            />
          </Form.Item>
        </Form>
        {/* Display the slug */}
        <div className="text-gray-500 rounded mt-8 bg-gray-50 text-base border-primary border px-3 py-2 h-[42px]">
          {slug}
        </div>
      </div>
    </Modal>
  )
}

import React, { useState } from "react"
import Icon from "@ant-design/icons"
import MyHeader from "./Header/Header"
import { useStore } from "components/ui"
import Navbar from "./Navbar"
import { useLocation } from "react-router-dom"
import DropdownProjects from "components/Dropdown/DropdownProjects"
import "./index.css"
import { OBJECT_PAGE } from "lib/Const"
import { convertPermission } from "utils/utils"

function convertMenus(menus) {
  const permission = JSON.parse(localStorage.getItem("permission"))
  const objPermission = convertPermission(permission)
  let __menus = []
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i]
    const IconX = () => <span dangerouslySetInnerHTML={{ __html: menu.icon }} />
    let __children = []
    const newChildren = menu.sub_menu
    // const permission = permission[menu.code]
    if (newChildren?.length > 0) {
      for (let j = 0; j < newChildren?.length; j++) {
        const IconY = () => <span dangerouslySetInnerHTML={{ __html: newChildren[j].icon }} />
        const item = {
          url: menu.url + newChildren[j].url,
          title: newChildren[j].title,
          icon: <Icon component={IconY} />,
          tag: menu.tag
        }
        // const menuPermissionSub = permission[newChildren[j].code]
        if (objPermission[newChildren[j].code] || newChildren[j].code === "all")
          __children.push(item)
      }
    }
    if (objPermission[menu.code] || menu.code === "all") {
      __menus.push({
        url: menu.url,
        title: menu.title,
        icon: <Icon component={IconX} />,
        children: __children,
        tag: menu.tag
      })
    }
  }

  return __menus
}

const menus = [
  {
    title: "TOP",
    icon: '\n<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">\n  <path strokeLinecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />\n</svg>\n',
    tag: "all",
    url: "/",
    code: "all",
    is_special: false,
    sub_menu: []
  }
]

function LayoutProjectList({ children }) {
  const isShow = localStorage.getItem("isShow")
  const { user } = useStore()
  const [isNav, setIsNav] = useState(isShow === "false" ? false : true)

  function togleSidebar(e) {
    localStorage.setItem("isShow", !isNav)
    setIsNav((c) => !c)
  }

  return (
    <div className="flex min-h-screen">
      <Navbar items={convertMenus(menus)} isNav={isNav} />
      <section className="flex-grow w-0 bg_layout_content">
        <header className="flex justify-between py-2 border-b bg-white pr-6 pl-4 h-14 w-full sticky top-0 z-20">
          <div className="flex items-center gap-4">
            <div
              onClick={togleSidebar}
              className={`text-black rounded cursor-pointer flex items-center`}
            >
              {icD}
            </div>
            {useLocation()?.pathname !== "/projects" && (
              <div className="">
                <DropdownProjects />
              </div>
            )}
          </div>

          <MyHeader user={user} />
        </header>
        <div className="p-6">{children}</div>
      </section>
    </div>
  )
}
export default LayoutProjectList

const icD = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-8 w-8"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h8m-8 6h16" />
  </svg>
)

import Client from "../client/ClientControlPlane"
import version from "../version"

const resource = version + "/internal/user-group-projects"

function gets(params) {
  return Client("root_project_resource.project_resource_path_view").get(`${resource}/`, {
    params
  })
}
function getRoleOfProject(id, params) {
  return Client("root_project_resource.project_resource_path_view").get(
    `${version}/internal/group-projects/${id}/all/`,
    { params }
  )
}
function create(data) {
  return Client("root_project_resource.project_resource_path_create").post(
    `${resource}/create/multiple/`,
    data
  )
}
function remove(id, params) {
  return Client("root_project_resource.project_resource_path_delete").delete(`${resource}/${id}/`, {
    params
  })
}

const api = {
  gets,
  create,
  remove,
  getRoleOfProject
}
export default api

import queryString from "query-string"
import { getConfig } from "./localstorage"
import { ENUMS_CONFIG } from "./Const"
import { iconWarning } from "./icons"

export function convertPermission(permission) {
  const list = Object.keys(permission)
  let objPermission = {}
  for (let i = 0; i < list.length; i++) {
    let __path = list[i]?.split(".")[0]
    let values = permission[list[i]]
    if (!objPermission[__path]) objPermission[__path] = {}
    for (let i = 0; i < values.length; i++) {
      if (values[i].method === "*") {
        objPermission[__path] = {
          DELETE: true,
          POST: true,
          PUT: true,
          GET: true,
          CLOSE: true,
          DETAIL: true,
          ATRACT: true,
          APPROVAL: true,
          VIEW: true
        }
        break
      } else {
        objPermission[__path][values[i].method] = true
      }
    }
  }
  return objPermission
}

export default function convertTransalte() {
  let translates = {
    en: "English"
  }
  const translatesStore = localStorage.getItem("language")
  if (translatesStore) translates = JSON.parse(translatesStore)
  let rows = Object.keys(translates).map((i) => {
    return {
      label: translates[i],
      value: i
    }
  })
  return rows
}

export function convertAction(obj, method) {
  const strPermission = localStorage.getItem("permission")
  let permission = []
  if (strPermission) permission = JSON.parse(strPermission)
  for (let i = 0; i < permission.length; i++) {
    if (permission[i].object === obj && permission[i].method === method) return true
  }
  return false
  // permission
}

export function convertModal({ text, t }) {
  return {
    className: "modal_confirm",
    icon: null,
    centered: true,
    title: (
      <div>
        <div className="flex justify-center">{iconWarning}</div>
        <div className="text-center">{t("I4019_1209;645_18155")}</div>
      </div>
    ),
    cancelText: t("I4019_1209;645_18159;30_32137"),
    content: <div className="text-center text-gray-500">{text}</div>,
    okText: t("I4019_1442;645_18160;30_31931")
  }
}

export function convertDurationToClock(n) {
  let h = Math.floor(n / 3600)
  let m = n % 3600
  let _m = Math.floor(m / 60)
  let s = m % 60
  if (h === 0) {
    h = ""
  } else {
    h = h + ":"
  }
  if (_m < 10) _m = "0" + _m
  if (s < 10) s = "0" + s
  return h + _m + ":" + s
}

export function converDataTree(_list, filter) {
  let list = [..._list]
  function loop(__list) {
    for (let i = 0; i < __list.length; i++) {
      __list[i].value = __list[i].id
      __list[i].title = __list[i].name

      if (__list[i].children && __list[i].children.length > 0) {
        if (filter) {
          __list[i].selectable = true
        } else {
          __list[i].selectable = false
        }
        loop(__list[i].children)
      }
    }
  }
  loop(list)
  return list
}

export const paramsUrl = {
  get: () => {
    return queryString.parse(window.location.search)
  },
  set: (params) => {
    const currentUrlParams = queryString.stringify(params, {
      skipNull: true
    })
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${currentUrlParams.toString()}`
    )
  }
}

export function getSearchParams() {
  return queryString.parse(window.location.search)
}

export function getRole() {
  const role = localStorage.getItem("role")
  return role || false
}

export function fomatCurrency(number) {
  if (number) {
    return parseInt(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
  }
  return 0
}

export function getToken() {
  return localStorage.getItem("accessToken")
}

export function getNameConfig(type, value) {
  const typeConfigs = getConfig(type)
  return typeConfigs.find((i) => i.value === value)?.label
}

export function convertLink(url, baseUrl) {
  const baseLink = getConfig("base_link")
  if (url?.includes("http")) return url
  return baseUrl ? baseUrl + url : baseLink?.url_img + url
}
export function convertEnumToDropdown(key, t) {
  return ENUMS_CONFIG[key]?.map((i) => {
    const name = t ? t(i.label) : i.label
    return {
      label: name,
      value: i.value
    }
  })
}

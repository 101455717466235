import Client from "../client/ClientControlPlane"
import ClientControlIDM from "../client/ClientControlIDM"
import version from "../version"
import ClientAms from "../client/ClientAms"

const resource = version + "/internal/applications"
const app_versions = version + "/internal/app_versions"
const app_manuals = version + "/internal/app_manuals"
const app_manual_translation = version + "/internal/app_manual_translation/"
const api_config = "/api/config"

function gets(params) {
  return Client("root_application.application_path_view").get(`${resource}/`, { params })
}
function get(id) {
  return Client("root_application.application_path_detail").get(`${resource}/${id}/`)
}
function create(data) {
  return Client("root_application.application_path_create").post(`${resource}/`, data)
}
function update(id, data) {
  return Client("root_application.application_path_update").put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client("root_application.application_path_delete").delete(`${resource}/${id}/`)
}

function updateMutil(data) {
  return Client("root_application.application_path_update").put(
    `${resource}/change-status/multiple/`,
    data
  )
}
function removeMutil(data) {
  return Client("root_application.application_path_delete").put(
    `${resource}/delete/multiple/`,
    data
  )
}

function getVersions(params) {
  return Client("root_app_versions.app_versions_path_view").get(
    `${app_versions}/get_by_application_code/`,
    { params }
  )
}

function removeVersion(id) {
  return Client("root_application.app_versions_path_delete").delete(`${app_versions}/${id}/`)
}

function createVersion(data) {
  return Client("root_application.app_versions_path_create").post(`${app_versions}/create/`, data)
}

function updateVersion(id, data) {
  return Client("root_application.app_versions_path_update").put(`${app_versions}/${id}/`, data)
}

function getVersionDetail(id) {
  return Client("root_application.app_versions_path_detail").get(`${app_versions}/${id}/`)
}

function getManuals(params) {
  return Client("root_app_manual.app_manual_path_view").get(`${app_manuals}/`, { params })
}

function createManual(data) {
  return Client("root_app_manual.app_manual_path_create").post(`${app_manuals}/`, data)
}

function removeManual(id, data) {
  return Client("root_app_manual.app_manual_path_delete").delete(`${app_manuals}/${id}/`, {
    data
  })
}

function updateManual(id, data) {
  return Client("root_app_manual.app_manual_path_update").put(`${app_manuals}/${id}/`, data)
}

function getManualDetail(id) {
  return Client("root_app_manual.app_manual_path_detail").get(`${app_manuals}/${id}/`)
}

function getManualTranslation(params) {
  return Client("root_app_manual.app_manual_translation_path_view").get(
    `${app_manual_translation}`,
    {
      params
    }
  )
}
function getCountry() {
  return ClientAms().get(`${api_config}`)
}

function getTerms(params) {
  return ClientControlIDM("root_term.term_path_view").get(`${version}/user/term/list`, {
    params
  })
}
function removeTerms(id) {
  return ClientControlIDM("root_term.term_path_delete").delete(`${version}/user/term/delete/${id}/`)
}

function createTerms(data) {
  return ClientControlIDM("root_term.term_path_create").post(`${version}/user/term/create/`, data)
}

function updateTerms(id, data) {
  return ClientControlIDM("root_term.term_path_update").put(
    `${version}/user/term/update/${id}/`,
    data
  )
}

function getTermsDetail(id) {
  return ClientControlIDM("root_term.term_path_detail").get(`${version}/user/term/detail/${id}/`)
}

function getPrivacyPolicies(params) {
  return ClientControlIDM("root_privacy_policy.privacy_policy_path_view").get(
    `${version}/user/privacy-policy/list`,
    { params }
  )
}

function removePrivacyPolicy(id) {
  return ClientControlIDM("root_privacy_policy.privacy_policy_path_delete").delete(
    `${version}/user/privacy-policy/delete/${id}/`
  )
}

function createPrivacyPolicy(data) {
  return ClientControlIDM("root_privacy_policy.privacy_policy_path_create").post(
    `${version}/user/privacy-policy/create/`,
    data
  )
}

function updatePrivacyPolicy(id, data) {
  return ClientControlIDM("root_privacy_policy.privacy_policy_path_update").put(
    `${version}/user/privacy-policy/update/${id}/`,
    data
  )
}

function getPrivacyPolicyDetail(id) {
  return ClientControlIDM("root_privacy_policy.privacy_policy_policy_path_detail").get(
    `${version}/user/privacy-policy/detail/${id}/`
  )
}

function createSendNotice(data) {
  return ClientControlIDM("root_notice.notice_path_create").post(
    `${version}/user/notice/create/`,
    data
  )
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  updateMutil,
  removeMutil,
  getVersions,
  removeVersion,
  createVersion,
  updateVersion,
  getVersionDetail,
  getManuals,
  createManual,
  removeManual,
  updateManual,
  getManualDetail,
  getManualTranslation,
  getCountry,
  getTerms,
  removeTerms,
  createTerms,
  updateTerms,
  getTermsDetail,
  getPrivacyPolicies,
  removePrivacyPolicy,
  createPrivacyPolicy,
  updatePrivacyPolicy,
  getPrivacyPolicyDetail,
  createSendNotice
}
export default api

const localeDefault = {
  "108_1789": "Name",
  "124_6494": "Alert",
  "124_6478": "Access Limit",
  "4030_3491": "Show",
  "4030_3474": "Wrapper Group 22",
  "124_6474": "Traffic Control",
  "124_6457": "AMS",
  "124_6453": "Apps",
  "124_6502": "Dowload SDK",
  "4030_3496": "of",
  "4030_3487": "page",
  "4030_3480": "1",
  "4030_4125": "/ ",
  "124_6434": "5",
  "124_6441": "Setting",
  "124_6436": "Develop Console",
  "4030_4124": "Wrapper Group",
  "4030_3476": "Description",
  "108_1727": "Wrapper Group...",
  "124_6487": "Access Logs",
  "4030_3492": "1",
  "4030_3486": "10  /",
  "4030_3497": "results",
  "124_6482": "Analytics",
  "108_1729": "Action",
  "108_1661": "Wrapper Group",
  "4030_4123": "API Gateway ",
  "4030_3455": "Wrapper Group 22",
  "4030_3494": "38",
  "108_1785": "Action",
  "4030_3457": "Description",
  "108_1739": "Create",
  "108_1791": "Description",
  "4030_3495": "to",
  "124_6466": "Wraper API",
  "124_6498": "Alert Rules",
  "124_6439": "Project Overview",
  "124_6507": "API Testing",
  "124_6446": "API Gateway",
  "124_6470": "Wraper Group",
  "4030_3493": "10",
  "124_6363": "API Gateway",
  "109_1860": "Name",
  "4030_3765": "Create Wrapper Group",
  "109_1863": "Back",
  "4030_3771": "API Gateway",
  "124_6387": "Wraper Group",
  "124_6370": "Apps",
  "109_1879": "Enter name",
  "109_1865": "Next",
  "124_6424": "API Testing",
  "124_6356": "Project Overview",
  "4030_3773": "Wrapper Group",
  "109_1946": "Enter Wrapper",
  "124_6411": "Alert",
  "124_6351": "5",
  "109_1861": "Description",
  "124_6395": "Access Limit",
  "124_6419": "Dowload SDK",
  "124_6353": "Develop Console",
  "124_6383": "Wraper API",
  "124_6404": "Access Logs",
  "124_6358": "Setting",
  "4030_3772": "/",
  "109_1883": "Enter description.",
  "124_6374": "AMS",
  "182_15774": "Please fill the Name",
  "124_6399": "Analytics",
  "109_1944": "Wrappers",
  "124_6415": "Alert Rules",
  "124_6391": "Traffic Control",
  "4030_4147": "page",
  "4030_4153": "10",
  "109_2668": "Created Date",
  "109_2653": "Action",
  "109_2768": "Replenish Rate",
  "4030_4155": "to",
  "109_2666": "Name",
  "4030_4133": "/ ",
  "109_2651": "Search...",
  "4030_4154": "38",
  "4030_4140": "1",
  "109_2766": "Burst Capacity",
  "4030_4132": "API Gateway ",
  "4030_4157": "results",
  "4030_4151": "Show",
  "4030_4156": "of",
  "124_6114": "API Gateway",
  "124_6142": "Traffic Control",
  "2047_451": "Time Range To",
  "124_6102": "5",
  "4030_4146": "10  /",
  "4030_4112": "test",
  "4030_4116": "17:19 21-02-2024",
  "109_2645": "Traffic Control",
  "2047_453": "Time Range From",
  "4030_4118": "3",
  "109_2662": "Action",
  "4030_4134": "Traffic Control",
  "4030_4152": "1",
  "109_2655": "Create",
  "4030_4120": "2",
  "124_6185": "5",
  "124_6192": "Setting",
  "124_6217": "Wraper API",
  "124_6238": "Access Logs",
  "124_6187": "Develop Console",
  "109_2783": "Enter name",
  "4030_4267": "Traffic Control",
  "182_15777": "Please fill the Burst Capacity",
  "109_2779": "Back",
  "124_6204": "Apps",
  "124_6258": "API Testing",
  "109_2888": "Enter Burst Capacity",
  "109_2885": "Enter Replenish Rate",
  "124_6221": "Wraper Group",
  "124_6190": "Project Overview",
  "4030_4259": "Create Traffic Control",
  "109_2775": "Name",
  "124_6249": "Alert Rules",
  "124_6229": "Access Limit",
  "124_6245": "Alert",
  "4030_4266": "/",
  "4030_4265": "API Gateway",
  "124_6253": "Dowload SDK",
  "182_15775": "Please fill the Name",
  "109_2894": "1",
  "109_2886": "Burst Capacity",
  "109_2785": "Enter Wrapper",
  "109_2776": "Wrapper API",
  "124_6208": "AMS",
  "109_2883": "Replenish Rate",
  "109_2781": "Next",
  "124_6225": "Traffic Control",
  "124_6233": "Analytics",
  "182_15778": "Please choose Wrapper API",
  "182_15776": "Please fill the Rate Limit",
  "109_2892": "Requested Tokens",
  "124_6197": "API Gateway",
  "124_6083": "Alert Rules",
  "112_3730": "App Version",
  "112_3776": "Log Detail",
  "112_3757": "User Info",
  "124_6051": "Wraper API",
  "124_6021": "Develop Console",
  "112_3763": "Exception",
  "124_6055": "Wraper Group",
  "124_6031": "API Gateway",
  "124_6026": "Setting",
  "112_3759": "admin@organiclink.cloud",
  "124_6063": "Access Limit",
  "112_3744": "51a10809",
  "4038_6448": "/ ",
  "112_3728": "App Name",
  "4038_6447": "API Gateway ",
  "124_6024": "Project Overview",
  "112_3748": "State",
  "124_6087": "Dowload SDK",
  "112_3751": "Log Level",
  "112_3742": "Request ID",
  "112_3740": "Extra Code",
  "112_3770": "Method",
  "112_3766": "Global ID",
  "112_3774": "GET",
  "112_3745": "Visit No",
  "4038_6449": "Detail Log",
  "124_6019": "5",
  "124_6079": "Alert",
  "112_3753": "INFO",
  "124_6042": "AMS",
  "4038_6443": "Detail Log",
  "112_3769": "Status Code",
  "112_3723": "Device OS",
  "112_3726": "App Auth Code",
  "124_6038": "Apps",
  "112_3734": "Event ID",
  "112_3750": "OK",
  "124_6059": "Traffic Control",
  "112_3754": "User ID",
  "112_3762": "GET",
  "112_3772": "200",
  "112_3725": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7)...",
  "112_3747": "14:17:57 01-02-2024",
  "112_3768": "fa094457-cc3e-4258-aee2-66e22de127b7",
  "112_3756": "50",
  "124_6092": "API Testing",
  "112_3609": "Application ID",
  "124_6072": "Access Logs",
  "112_3760": "Action Name",
  "124_6067": "Analytics",
  "4038_6812": "1",
  "4038_6714": "Alert Rules",
  "124_5760": "API Testing",
  "4038_6807": "page",
  "124_5687": "5",
  "124_5740": "Access Logs",
  "124_5751": "Alert Rules",
  "4038_6806": "10  /",
  "4038_6817": "results",
  "124_5706": "Apps",
  "115_3822": "Name",
  "124_5689": "Develop Console",
  "4038_6811": "Show",
  "4038_6815": "to",
  "124_5747": "Alert",
  "124_5723": "Wraper Group",
  "4038_6718": "API Gateway ",
  "124_5735": "Analytics",
  "124_5719": "Wraper API",
  "115_3818": "Action",
  "124_5727": "Traffic Control",
  "4038_6720": "Alert Rules",
  "124_5692": "Project Overview",
  "124_5731": "Access Limit",
  "115_4160": "User",
  "115_3807": "Search",
  "124_5694": "Setting",
  "115_3824": "Created By",
  "115_4168": "Type",
  "115_4164": "Status",
  "115_3809": "Action",
  "4038_6813": "10",
  "115_3811": "Create",
  "115_3826": "Status",
  "124_5710": "AMS",
  "115_3832": "No data",
  "124_5699": "API Gateway",
  "115_3828": "Content",
  "4038_6814": "38",
  "124_5755": "Dowload SDK",
  "4038_6816": "of",
  "4038_6719": "/ ",
  "4038_6800": "1",
  "190_1056": "Please fill the Condition",
  "124_5770": "5",
  "124_5843": "API Testing",
  "190_1057": "Please fill the Value",
  "118_4181": "Type",
  "118_4329": "User",
  "118_4312": "Resource",
  "124_5814": "Access Limit",
  "118_4177": "Name",
  "118_4314": "Services",
  "124_5789": "Apps",
  "118_4183": "Back",
  "4038_6728": "/ ",
  "118_4291": "Status",
  "190_1055": "Please fill the Resource",
  "118_4185": "Next",
  "4038_6727": "API Gateway ",
  "118_4324": "value",
  "124_5775": "Project Overview",
  "118_4327": "Notification",
  "124_5823": "Access Logs",
  "124_5818": "Analytics",
  "124_5793": "AMS",
  "124_5834": "Alert Rules",
  "118_4191": "Content",
  "124_5810": "Traffic Control",
  "124_5772": "Develop Console",
  "124_5802": "Wraper API",
  "124_5838": "Dowload SDK",
  "118_4187": "Name",
  "118_4195": "STATUS CODE ",
  "190_1052": "Please fill the content!",
  "118_4317": "Condition",
  "124_5782": "API Gateway",
  "118_4296": "Rule Condition",
  "118_4322": "Value",
  "118_4319": "Condition",
  "190_1054": "Please fill the Users",
  "124_5777": "Setting",
  "118_4293": "ACTIVE",
  "4038_6729": "Alert Rules",
  "118_4179": "Message",
  "4038_6722": "Create Alert Rules",
  "124_5830": "Alert",
  "124_5806": "Wraper Group",
  "190_1051": "Please fill the Name!",
  "119_4614": "Rule Name",
  "124_5553": "Wraper API",
  "119_4490": "Status",
  "124_5574": "Access Logs",
  "124_5557": "Wraper Group",
  "124_5533": "API Gateway",
  "119_4780": "GET",
  "124_5523": "Develop Console",
  "119_4658": "Message Alert Log Status Code 200",
  "119_4850": "Unresolved",
  "4038_6460": "10  /",
  "124_5565": "Access Limit",
  "119_4812": "OK",
  "119_4777": "Method",
  "4130_2351": "Start Date",
  "4038_6477": "API Gateway ",
  "119_4854": "Resolved",
  "119_4759": "Unresolved",
  "4038_6471": "results",
  "124_5544": "AMS",
  "124_5581": "Alert",
  "119_4648": "Action",
  "119_4868": "Confirm",
  "124_5561": "Traffic Control",
  "4038_6478": "/ ",
  "119_4486": "User",
  "124_5528": "Setting",
  "119_4870": "Confirm",
  "119_4482": "Search",
  "119_4830": "OK",
  "4038_6479": "Alert",
  "4038_6461": "page",
  "4038_6467": "10",
  "4038_6465": "Show",
  "4038_6454": "1",
  "124_5521": "5",
  "119_4872": "Done",
  "4130_2354": "End Date",
  "119_4841": "Alert Log Status Code 200",
  "119_4494": "Type",
  "124_5569": "Analytics",
  "119_4654": "Created Date",
  "124_5594": "API Testing",
  "119_4740": "16:30:04 01-02-2024",
  "4038_6468": "38",
  "119_4798": "GET",
  "119_4652": "Message",
  "4038_6469": "to",
  "124_5540": "Apps",
  "119_4833": "OK",
  "4038_6473": "Alert",
  "119_4737": "Message Alert Log Status Code 200",
  "119_4809": "LOG",
  "124_5589": "Dowload SDK",
  "119_4616": "Status",
  "4038_6470": "of",
  "119_4661": "16:30:04 01-02-2024",
  "119_4733": "16:30:04 01-02-2024",
  "119_4484": "Action",
  "119_4730": "Message Alert Log Status Code 200",
  "119_4756": "Alert Log Status Code 200",
  "124_5585": "Alert Rules",
  "124_5526": "Project Overview",
  "119_4801": "GET",
  "119_4840": "Alert Log Status Code 200",
  "4038_6466": "1",
  "4072_1525": "Integration",
  "12_10744": "Integration",
  "13_10826": "Manage",
  "13_10824": "image 1",
  "4074_1733": "  /",
  "12_10801": "Integration",
  "12_10769": "5",
  "4072_1524": "Project Setting ",
  "12_10776": "Setting",
  "12_7867": "Project Name",
  "12_7865": "Create Project",
  "12_7868": "Let start with a name for your project",
  "12_7875": "Cancel",
  "12_7877": "Create",
  "12_7870": "Enter your project name",
  "12_8118": "Users",
  "12_8123": "Wrapper Group",
  "12_8074": "Overview",
  "12_8188": "Know your customers",
  "12_8119": "2",
  "12_8174": "API Gateway",
  "12_8032": "Services",
  "12_8078": "5",
  "12_8156": "Last 24 Hours",
  "12_8114": "1",
  "12_8182": "AWS",
  "12_8033": "10",
  "12_8113": "Apps",
  "12_8148": "Wrapper API",
  "12_8180": "Build a Flexible API Gateway that feed your needed",
  "12_8162": "1.738",
  "12_8022": "Dashboard",
  "12_8169": "59",
  "12_8124": "2",
  "12_8190": "Analyst",
  "12_8159": "Last 24 Hours",
  "12_8154": "API Calls",
  "12_8168": "API Fail",
  "12_8181": "Inactive",
  "12_8161": "API Success",
  "12_8149": "3",
  "12_8176": "Active",
  "12_8155": "1.797",
  "12_8175": "Build a Flexible API Gateway that feed your needed",
  "2216_797": "Develop Portal",
  "12_8189": "Active",
  "12_8166": "Last 24 Hours",
  "2216_783": "TOP",
  "4072_1518": " Overview",
  "12_8781": "Overview",
  "2011_1467": "20240206042713490017",
  "2001_1340": "Project Setting",
  "12_8282": "Setting",
  "12_10740": "Development",
  "4074_1732": "  /",
  "12_8305": "Overview",
  "144_12458": "Delete Project",
  "12_10735": "Project Code",
  "2011_1468": "Please fill your environment",
  "12_8785": "Project Name",
  "12_8806": "om-admin-test-ams",
  "12_8788": "Project Code",
  "12_8790": "Description",
  "12_8812": "Enter your description",
  "2011_1465": "Project Number",
  "12_8275": "5",
  "12_8784": "Project Setting ",
  "12_10737": "om-admin-test-ams",
  "12_8796": "Demo 24 Jan",
  "12_8794": "Save",
  "12_10738": "Environment",
  "12_8792": "Back",
  "124_5391": "Access Logs",
  "I2040_426;1165_166082": "Download  for ",
  "124_5340": "Develop Console",
  "124_5374": "Wraper Group",
  "124_5382": "Access Limit",
  "I2040_428;1165_166072": "AMS Analyst",
  "I2040_427;1165_166072": "API Gateway",
  "4038_7251": "SDK Downloads",
  "124_5406": "API Testing",
  "124_5338": "5",
  "124_5361": "AMS",
  "4038_7256": "API Gateway ",
  "I2040_426;1165_166073": "Build a Flexible API Gateway that feed your needed",
  "124_5357": "Apps",
  "124_5402": "Alert Rules",
  "I2040_427;1165_166073": "Globalize your application, made easy with the power of multilingual",
  "I2040_427;1165_166082": "Download  for ",
  "4038_7257": "/ ",
  "I2040_428;1165_166082": "Download  for ",
  "124_5350": "API Gateway",
  "124_5370": "Wraper API",
  "124_5398": "Alert",
  "124_5378": "Traffic Control",
  "I2040_426;1165_166072": "API Gateway",
  "124_5386": "Analytics",
  "124_5345": "Setting",
  "124_5343": "Project Overview",
  "I2040_428;1165_166073": "Know your customers",
  "4038_7258": "SDK Downloads",
  "124_5430": "Dowload SDK",
  "124_6957": "API Gateway",
  "124_7009": "Alert Rules",
  "124_6985": "Traffic Control",
  "124_6947": "Develop Console",
  "124_6993": "Analytics",
  "4038_7246": "API Gateway ",
  "124_7041": "APIs",
  "124_6964": "Apps",
  "124_6981": "Wraper Group",
  "124_6998": "Access Logs",
  "124_6945": "5",
  "124_6950": "Project Overview",
  "124_7046": "Select Request",
  "124_6977": "Wraper API",
  "124_6952": "Setting",
  "4038_7247": "/ ",
  "124_7042": "Create Folder",
  "124_6968": "AMS",
  "124_7018": "API Testing",
  "4038_7242": "Create Folder",
  "4038_7248": "API Testing",
  "124_6989": "Access Limit",
  "124_7005": "Alert",
  "124_7013": "Dowload SDK",
  "14_11035": "Linkage Service",
  "4072_1544": ": dc1",
  "14_11048": "Setting",
  "14_11041": "5",
  "4072_1529": ": 2",
  "4072_1527": "Linkage Service",
  "4072_1534": "Instance Count",
  "4072_1543": "Datacenter",
  "4072_1536": "Datacenter",
  "4072_1537": ": dc1",
  "4072_1539": "Name",
  "14_11075": "Linkage Service",
  "4072_1532": "Name",
  "4074_1735": "  /",
  "4072_1530": ": dc1",
  "4072_1540": ": Mazda",
  "14_11171": "Instance Count",
  "14_11170": "Name",
  "4072_1533": ": Honda",
  "4072_1542": ": 2",
  "14_11172": "Datacenter",
  "4072_1541": "Instance Count",
  "4072_1535": ": 2",
  "4072_1528": ": Consul",
  "4019_1285": "Project Setting  ",
  "4019_1314": "InActive",
  "17_430": "Create",
  "17_485": "16:55 25-01-2024",
  "16_124": "Apps",
  "4019_1322": "1",
  "4019_1305": "om-admin-demo-\n12pm-test-12",
  "4019_1335": "10",
  "4019_1337": "to",
  "17_464": "Name",
  "17_487": "Status",
  "16_99": "Apps",
  "17_377": "Icon",
  "4019_1328": "10  /",
  "17_490": "Active",
  "17_504": "om-admin-demo-\n12pm-test-12",
  "4019_1329": "page",
  "4019_1338": "of",
  "4019_1339": "results",
  "4019_1310": "16:55 25-01-2024",
  "16_105": "5",
  "4019_1302": "Web",
  "4019_1296": "Test 12",
  "17_479": "Description",
  "17_468": "Test 12",
  "17_501": "App Code",
  "4019_1333": "Show",
  "4019_1336": "38",
  "17_381": "Created",
  "17_383": "Action",
  "17_428": "App Name...",
  "17_455": "Action",
  "17_375": "Platform",
  "17_494": "Oauth Scopes",
  "17_451": "Enter Platform",
  "17_398": "Web",
  "17_447": "Active",
  "4019_1334": "1",
  "193_1128": "Avatar",
  "193_1105": "Apps",
  "193_1180": "Add member",
  "4019_1260": "1",
  "193_1088": "Develop Console",
  "193_1098": "API Gateway",
  "193_1144": "son.vu@organicmobi.com",
  "193_1166": "Koji Matsubara",
  "4019_1255": "page",
  "193_1091": "Project Overview",
  "193_1130": "Role",
  "193_1132": "Action",
  "193_1120": "Linkage Service",
  "193_1118": "Integration",
  "4019_1263": "to",
  "4019_1261": "10",
  "4019_1262": "38",
  "193_1109": "AMS",
  "4019_1259": "Show",
  "193_1126": "Email",
  "193_1119": "User & Permission",
  "4019_1248": "1",
  "193_1124": "Full Name",
  "193_1151": "Editor",
  "193_1163": "admin@organiclink.cloud",
  "4019_1265": "results",
  "193_1080": "User & Permission",
  "193_1086": "5",
  "4074_1734": "  /",
  "4019_1264": "of",
  "193_1170": "Owner",
  "193_1083": "Project Setting  ",
  "193_1178": "Functions Setting Name",
  "4019_1254": "10  /",
  "193_1147": "Son Vu",
  "193_1093": "Setting",
  "4072_1526": "User & Permission",
  "193_1117": "Overview",
  "144_12794": "Add Member",
  "193_1223": "Warning",
  "193_1253": "Are your sure to active User?",
  "4005_2181": "Cancel",
  "193_1256": "Warning",
  "144_12788": "Add Member",
  "193_1230": "Notification",
  "193_1250": "Cancel",
  "193_1217": "Cancel",
  "193_1220": "Are your sure to delete User?",
  "144_12779": "User",
  "4005_2187": "Warning",
  "193_1231": "Deleted successful!",
  "4019_1276": "Edit",
  "4005_2184": "Are your sure to deactive User?",
  "144_12818": "Choose users!",
  "144_12777": "Role",
  "144_12819": "Choose role!",
  "4019_1280": "Delete",
  "144_12783": "Role",
  "144_12776": "Email",
  "144_12792": "Cancel",
  "193_1219": "Confirm",
  "4005_2183": "Confirm",
  "193_1252": "Confirm",
  "2047_533": "Wraper Group",
  "4030_4772": "page",
  "2047_467": "Action",
  "2047_469": "Status",
  "2047_561": "Alert Rules",
  "4030_4760": "/ ",
  "2047_473": "Create",
  "2047_545": "Analytics",
  "2047_550": "Access Logs",
  "2047_529": "Wraper API",
  "2047_484": "Name",
  "2047_516": "Apps",
  "2047_490": "Status",
  "4030_4778": "10",
  "2047_570": "API Testing",
  "2047_509": "API Gateway",
  "2047_486": "Time Range To",
  "4030_4780": "to",
  "4030_4782": "results",
  "4030_4777": "1",
  "4030_4761": "Access Limit",
  "2047_565": "Dowload SDK",
  "4030_4771": "10  /",
  "4030_4776": "Show",
  "2047_480": "Action",
  "4030_4781": "of",
  "2047_541": "Access Limit",
  "2047_459": "Access Limit",
  "2047_488": "Time Range From",
  "2047_494": "No data",
  "4030_4759": "API Gateway ",
  "4030_4765": "1",
  "2047_537": "Traffic Control",
  "2047_502": "Project Overview",
  "4030_4779": "38",
  "2047_465": "Search",
  "2047_557": "Alert",
  "2047_499": "Develop Console",
  "2047_504": "Setting",
  "2047_497": "5",
  "2047_520": "AMS",
  "2047_693": "Traffic Control",
  "2047_613": "Enter Wrapper API Group!",
  "2047_721": "Dowload SDK",
  "2047_617": "Type",
  "2047_665": "API Gateway",
  "4030_4828": "Create Access Limit",
  "2047_637": "Wrapper API Group",
  "2047_621": "SINGLE",
  "4120_2316": "To",
  "2047_655": "Develop Console",
  "2047_757": "Please Enter IP Time  Range From",
  "4030_4836": "Access Limit",
  "2047_752": "Time Range To",
  "2047_611": "Please fill the Name!",
  "2047_653": "5",
  "2047_632": "Wrapper API",
  "2047_645": "Add more",
  "2047_598": "Name",
  "2047_750": "Time Range From",
  "2047_697": "Access Limit",
  "2047_607": "ACTIVE",
  "4120_2303": "From",
  "2047_614": "Enter Wrapper API!",
  "4120_2318": "10.10.10.10",
  "2047_672": "Apps",
  "2047_701": "Analytics",
  "4120_2299": "RANGE",
  "2047_616": "IP",
  "2047_717": "Alert Rules",
  "2067_507": "App Callback URL",
  "2047_660": "Setting",
  "2047_626": "List of IP addresses will be allowed to access the API",
  "2047_605": "Name",
  "2047_676": "AMS",
  "4030_4835": "/",
  "4120_2302": "Type",
  "2047_706": "Access Logs",
  "2047_685": "Wraper API",
  "2047_599": "Status",
  "2047_641": "Apply To",
  "2047_689": "Wraper Group",
  "2047_603": "Next",
  "2047_601": "Back",
  "2047_726": "API Testing",
  "2047_658": "Project Overview",
  "2047_754": "Time Range From",
  "2047_713": "Alert",
  "2047_749": "Time Range To",
  "2047_650": "Time Range",
  "4030_4834": "API Gateway",
  "2067_506": "Please fill your App Callback URL! (https://example.com)",
  "2047_630": "Add more",
  "2047_748": "Please Enter IP Time  Range To",
  "4120_2305": "10.10.10.10",
  "2047_633": "Wrapper API Group",
  "2047_619": "10.10.10.10",
  "2047_612": "Please Enter IP Address",
  "2047_625": "Allowlist",
  "2067_505": "Callback URL",
  "2047_635": "Wrapper API",
  "4030_5771": "visit",
  "4030_5665": "API Gateway ",
  "4030_5689": "Duration",
  "4030_5699": "4",
  "4030_5721": "2024-03-07 01:55",
  "4030_5994": "response",
  "4030_5996": "(",
  "4030_5891": "0",
  "4030_5704": "2024-03-07",
  "4030_5779": "300",
  "4030_5722": "2024-03-07 01:55",
  "2047_595": "Create Access Limit",
  "4030_5775": "Duration",
  "4030_5609": "Analytics",
  "4030_5568": "Setting",
  "4030_6027": "2024-03-07",
  "4030_6067": "0",
  "4030_5677": "visitCount",
  "4030_6013": "analysis reporting time spent",
  "4030_5772": "Count",
  "4030_5949": "02:00",
  "4030_6009": "services",
  "4030_6052": "15",
  "4030_6036": "Count",
  "4030_5970": "200",
  "4030_6011": "analysis api visit count",
  "4030_6060": "Avg. Duration (Per Api)",
  "4030_5787": "70",
  "4030_5889": "01:00",
  "4030_5995": "time",
  "4030_6012": "whitelist ips",
  "4030_5678": "300",
  "4030_5726": "2024-03-07 01:55",
  "4030_5682": "100",
  "4030_6049": "200000",
  "4030_6014": "analysis avg duration per hour",
  "4030_6041": "visitCount",
  "4030_5723": "2024-03-07 01:55",
  "4030_5715": "2024-03-07 01:55",
  "4030_5766": "2024-03-07",
  "4030_5792": "20",
  "4030_5679": "250",
  "4030_5634": "API Testing",
  "4030_5987": "Times",
  "4030_5784": "50",
  "4030_5948": "03:00",
  "4030_6048": "400000",
  "4030_5584": "AMS",
  "4030_5692": "visit",
  "4030_5725": "2024-03-07 01:55",
  "4030_5716": "2024-03-07 01:55",
  "4030_6030": "2024-03-07",
  "4030_6039": "Duration",
  "4030_5966": "Avg",
  "4030_5687": "Avg",
  "4030_5774": "Avg",
  "4030_5763": "2024-03-07",
  "4030_5783": "100",
  "4030_6010": "traffic controls",
  "4030_5729": "2024-03-07 01:55",
  "4030_5724": "2024-03-07 01:55",
  "4030_6038": "Avg",
  "4030_5614": "Access Logs",
  "4030_6043": "1400000",
  "4030_5717": "2024-03-07 01:55",
  "4030_5781": "200",
  "4030_5605": "Access Limit",
  "4030_5713": "2024-03-07 01:55",
  "4030_5681": "150",
  "4030_5670": "Read - Time. Request",
  "4030_5580": "Apps",
  "4030_6053": "10",
  "4030_5601": "Traffic Control",
  "4030_6055": "0",
  "4030_5621": "Alert",
  "4030_5718": "2024-03-07 01:55",
  "4030_5720": "2024-03-07 01:55",
  "4030_5719": "2024-03-07 01:55",
  "4030_5666": "/ ",
  "4030_5683": "50",
  "4030_5727": "2024-03-07 01:55",
  "4030_5780": "250",
  "4030_5973": "50",
  "4030_6007": "analysis coumt",
  "4030_5625": "Alert Rules",
  "4030_5728": "2024-03-07 01:55",
  "4030_5573": "API Gateway",
  "4030_5790": "40",
  "4030_6040": "(ms)",
  "4030_5731": "2024-03-07 01:55",
  "4030_5629": "Dowload SDK",
  "4030_5734": "2024-03-07 03:01",
  "4030_5693": "Count",
  "4030_5684": "0",
  "4030_5698": "6",
  "4030_5972": "100",
  "4030_6044": "1200000",
  "4030_5566": "Project Overview",
  "4030_5563": "Develop Console",
  "4030_5712": "2024-03-07 01:55",
  "4030_5782": "150",
  "4030_6006": "Wrapper",
  "4030_5733": "2024-03-07 01:55",
  "4030_6051": "20",
  "4030_5661": "Monitoring",
  "4030_5696": "10",
  "4030_5791": "30",
  "4030_5971": "150",
  "4030_5561": "5",
  "4030_5711": "2024-03-07 01:55",
  "4030_5890": "10",
  "4030_5701": "0",
  "4030_5888": "02:00",
  "4030_5680": "200",
  "4030_6005": "Wrapper Group",
  "4030_5700": "2",
  "4030_5796": "Avg. Duration (Per Hour)",
  "4030_5714": "2024-03-07 01:55",
  "4030_6035": "visit",
  "4030_6045": "1000000",
  "4030_6046": "800000",
  "4030_6054": "5",
  "4030_5703": "2024-03-07",
  "4030_5597": "Wraper Group",
  "4030_5785": "0",
  "4030_5776": "(ms)",
  "4030_5730": "2024-03-07 01:55",
  "4030_5667": "Monitoring",
  "4030_5593": "Wraper API",
  "4030_5997": "Millisecond",
  "4030_5690": "(ms)",
  "4030_5788": "60",
  "4030_5950": "01:00",
  "4030_5777": "visitCount",
  "4030_6047": "600000",
  "4030_5998": ")",
  "4030_6178": "Monitoring",
  "4030_5955": "2024-03-07",
  "4030_6008": "permissions",
  "4030_5697": "8",
  "4030_5743": "03:00",
  "4030_5974": "0",
  "4030_5789": "50",
  "2097_792": "Please fill the phone code",
  "2096_783": "Enter Last Name!",
  "2092_965": "Personal data usage history",
  "2096_786": "Email is invalid!",
  "4038_7559": "User",
  "2092_905": "User Profile",
  "2092_955": "Last Name",
  "4038_7521": "5",
  "4038_7569": "Vehicles",
  "2092_973": "Role:",
  "2092_922": "Gender",
  "2092_909": "First Name",
  "2092_939": "Address",
  "4038_7573": "Identity Provider",
  "4038_7523": "IDM Portal",
  "4038_7553": "Administrator",
  "2092_958": "Update",
  "2096_785": "Enter Last Name!",
  "2092_974": "admin@organiclink.cloud",
  "2096_784": "Enter First Name!",
  "2092_972": "Owner OmAdmin",
  "4038_7539": "Policies",
  "2092_977": "Upload",
  "2092_945": "Enter City",
  "2092_919": "Nick name",
  "2092_969": "Log out",
  "2092_951": "Select Country...",
  "2097_794": "+84",
  "4038_7549": "Resources",
  "2096_788": "Please fill the phone",
  "2092_930": "Language",
  "4038_7535": "Organization",
  "2092_943": "Address 2",
  "2092_927": "Birthday",
  "2092_932": "English",
  "2092_936": "Email",
  "4038_7545": "User Roles",
  "2092_941": "Address 1",
  "2092_949": "State/Prefecture",
  "2092_947": "Postcode",
  "4038_7528": "Permission",
  "2097_791": "Phone ",
  "2097_789": "Please fill the phone",
  "4038_7526": "TOP",
  "4038_7565": "Applications",
  "2092_963": "Security",
  "2092_912": "Phone code",
  "4019_1596": "App",
  "22_280": "Platform",
  "22_141": "Apps",
  "163_13047": "Please fill your App Platform!",
  "22_267": "Back",
  "22_269": "Next",
  "22_279": "Enter your description",
  "22_290": "App Icon",
  "22_294": "Upload",
  "22_128": "Setting",
  "22_126": "Project Overview",
  "163_13046": "Please fill your App name!",
  "22_282": "Enter your project name",
  "22_261": "Name",
  "22_287": "Active",
  "22_285": "Status",
  "22_271": "Enter your app name",
  "22_123": "Develop Console",
  "22_265": "Description",
  "163_13048": "Please choose your App icon.",
  "22_145": "AMS",
  "4019_1595": "Create",
  "22_133": "API Gateway",
  "22_121": "5",
  "2216_825": "16",
  "2216_819": "Test OM",
  "2216_830": "0",
  "2216_930": "Develop Portal",
  "2216_809": "Demo 12PM",
  "2216_928": "TOP",
  "2216_814": "Test 13",
  "2216_834": "Test 13",
  "2216_852": "All Project",
  "4056_1373": "Dashboard",
  "2216_824": "Demo 12PM",
  "2216_815": "14",
  "2216_925": "5",
  "2216_845": "12",
  "2216_829": "OM AMS Demo",
  "2216_849": "Demo 24 Jan",
  "2216_805": "Add Project",
  "2216_799": "Developer Portal -",
  "2216_835": "14",
  "2216_850": "11",
  "2216_840": "12",
  "2216_851": "Recent Projects",
  "2216_839": "Test AMS 2",
  "2216_820": "6",
  "2216_844": "Test AMS 2",
  "2216_810": "16",
  "109_2152": "API Gateway",
  "23_346": "Description",
  "4021_1986": "Sports 22",
  "4021_2029": "SINGLE",
  "23_411": "Create",
  "4021_2048": "EXTERNAL",
  "4021_2054": "Active",
  "4021_2068": "page",
  "4021_2039": "InActive",
  "4021_2067": "10  /",
  "4021_2074": "10",
  "4021_2076": "to",
  "4021_2073": "1",
  "4021_2075": "38",
  "4030_4129": "/ ",
  "23_403": "Action",
  "4021_2033": "EXTERNAL",
  "23_401": "Wrapper name...",
  "23_451": "Import",
  "23_407": "Wrapper group...",
  "4021_2077": "of",
  "4021_1984": "SINGLE",
  "23_338": "Type",
  "23_342": "Wrapper name",
  "4021_1988": "EXTERNAL",
  "2059_766": "Export",
  "109_2172": "Wraper API",
  "4021_2031": "Sports 22",
  "109_2163": "AMS",
  "4021_2044": "SINGLE",
  "4021_2072": "Show",
  "4021_2078": "results",
  "23_399": "Status...",
  "4021_2061": "1",
  "23_354": "Action",
  "23_352": "Status",
  "4030_4128": "API Gateway ",
  "23_344": "Service type",
  "23_299": "Wrapper API",
  "4021_2046": "Sports 22",
  "4030_4130": "Wrapper API",
  "4021_1994": "Active",
  "109_2140": "5",
  "28_1042": "Method",
  "28_1044": "Groups",
  "182_15765": "Please fill the Name!",
  "124_6834": "Dowload SDK",
  "4022_2283": "Please fill the new value",
  "28_1220": "Enter Condition",
  "28_1053": "Enter consumer endpoint.",
  "4021_2203": "Please enter the Value",
  "4021_2220": "Type",
  "4023_2375": "Wrapper API",
  "28_1218": "Condition",
  "124_6768": "Develop Console",
  "124_6778": "API Gateway",
  "4022_2325": "Value",
  "4022_2311": "Enter type",
  "4022_2360": "Service",
  "28_1080": "Target",
  "4022_2291": "Add more",
  "28_1210": "Enter type",
  "4021_2181": "Type",
  "28_1226": "Enter value",
  "4021_2245": "Select Type",
  "4022_2336": "Param name",
  "28_1051": "Name A",
  "4022_2260": "Param name",
  "28_1047": "Back",
  "4021_2222": "Enter type",
  "4021_2197": "Value",
  "28_1059": "Active",
  "28_1102": "Service",
  "4022_2298": "Service",
  "4022_2281": "Please fill the Condition",
  "28_1203": "Condition",
  "182_15766": "Method!",
  "28_1208": "Type",
  "4022_2342": "Enter Condition",
  "28_1224": "Value",
  "4022_2302": "Enter service...",
  "124_6830": "Alert Rules",
  "4022_2254": "Type",
  "28_1089": "Multiple",
  "28_1101": "Post - Filtering",
  "4023_2368": "Create Wrapper API ",
  "4021_2217": "Add more",
  "4021_2204": "Pre - Filtering",
  "124_6766": "5",
  "4021_2189": "Enter param_na...",
  "124_6839": "API Testing",
  "28_1045": "Description",
  "4021_2200": "Select Type",
  "28_1106": "Enter service...",
  "28_1063": "Enter groups...",
  "4023_2374": "/",
  "4022_2346": "Value",
  "4023_2373": "API Gateway",
  "4022_2294": "Pre - Filtering",
  "4021_2249": "Please fill the new value",
  "4022_2266": "Enter Condition",
  "124_6806": "Traffic Control",
  "4022_2338": "Enter param_na...",
  "4021_2210": "Enter value",
  "4021_2211": "Please fill the new value",
  "28_1040": "Name",
  "28_1043": "Status",
  "4022_2292": "Service",
  "124_6819": "Access Logs",
  "124_6810": "Access Limit",
  "4022_2280": "Please fill the Param Name",
  "4022_2327": "Enter value",
  "4022_2364": "Post - Filtering",
  "4021_2230": "Condition",
  "4022_2319": "Condition",
  "4022_2340": "Condition",
  "124_6785": "Apps",
  "4022_2299": "Service",
  "4021_2193": "Enter Condition",
  "4022_2348": "Enter value",
  "28_1072": "Relative",
  "124_6773": "Setting",
  "4022_2272": "Enter value",
  "4021_2238": "Enter value",
  "4022_2270": "Value",
  "28_1214": "Param name",
  "4021_2236": "Value",
  "4022_2317": "Enter param_na...",
  "4022_2300": "Resource",
  "182_15771": "Please enter the Value",
  "28_1216": "Enter param_na...",
  "4022_2287": "Add more",
  "4022_2315": "Param name",
  "4022_2309": "Type",
  "124_6826": "Alert",
  "4021_2202": "Please fill the Condition",
  "4022_2330": "Type",
  "4021_2228": "Enter param_na...",
  "4022_2352": "Enter value",
  "4021_2247": "Please fill the Condition",
  "124_6814": "Analytics",
  "124_6789": "AMS",
  "4021_2232": "Enter Condition",
  "4021_2208": "New Value",
  "28_1049": "Next",
  "4022_2264": "Condition",
  "4022_2282": "Please enter the Value",
  "28_1078": "Enable IDM Auth",
  "4021_2191": "Condition",
  "4022_2276": "Enter value",
  "4022_2350": "New Value",
  "28_1041": "Consumer Endpoint",
  "28_1104": "Resource",
  "4021_2246": "Please fill the Param Name",
  "4021_2183": "Enter type",
  "124_6802": "Wraper Group",
  "182_15772": "Please choose the Service",
  "28_1099": "Add more",
  "4021_2187": "Param name",
  "4022_2361": ": 1/1",
  "28_1055": "Enter method.",
  "4022_2358": "Add more",
  "182_15768": "Select Type",
  "4022_2274": "New Value",
  "4022_2332": "Enter type",
  "182_15770": "Please fill the Condition",
  "4021_2226": "Param name",
  "4022_2359": ": 2/2",
  "182_15769": "Please fill the Param Name",
  "182_15773": "Please choose the Resource",
  "182_15767": "Please fill the Consumer Endpoint",
  "4021_2242": "Enter value",
  "4022_2256": "Enter type",
  "4022_2321": "Enter Condition",
  "124_6798": "Wraper API",
  "28_1103": "Service",
  "4021_2201": "Please fill the Param Name",
  "28_1085": "Single",
  "4021_2240": "New Value",
  "4022_2293": "Condition",
  "28_1164": "Enter resource",
  "4021_2199": "Enter value",
  "4022_2262": "Enter param_na...",
  "4022_2306": "Enter resource",
  "4021_2248": "Please enter the Value",
  "28_1067": "Enter description.",
  "124_6771": "Project Overview",
  "4022_2279": "Select Type",
  "1_7587": "Linkage Service",
  "1_7585": "Integation",
  "1_7567": "5",
  "4019_1120": "Admin Portal",
  "4394_1971": "Client Portal",
  "4004_1442": "Bien Hoang need you to Approval the Translation Request\n10:23 17/11/2023",
  "I4004_1429;366_12073": "UserName",
  "I4004_1429;643_12017": "Activity Logs",
  "4063_1502": "Monitoring",
  "4019_1126": "Develop Console",
  "I4004_1429;974_98967": "Profile Setting",
  "I4004_1429;636_27910": "ABC Corporation",
  "I4004_1432;405_13262;405_13239;144_59614": "9",
  "4004_1467": "Bien Hoang update Translation Task from TODO to DONE\n10:23 16/11/2023",
  "1_7588": "Apps",
  "4004_1457": "Bien Hoang need you to Approval 5 Wrapper API Creation Request\n07:23 16/11/2023",
  "4004_1462": "Bien Hoang need you to Approval the Translation Request\n10:23 16/11/2023",
  "1_7638": "Access Limit",
  "1_7679": "Design File Management",
  "I4004_1432;405_11590;405_11754": "All",
  "1_7584": "Overview",
  "4004_1453": "Thurday, 16 Nov 2023",
  "1_7633": "Traffic Control",
  "1_7599": "API Gateway",
  "4004_1469": "Scroll to load",
  "1_7697": "Translation Ratio",
  "1_7569": "Develop Console",
  "1_7576": "Setting",
  "I4004_1443;30_32021": "Approve",
  "1_7692": "System Error Manageme",
  "1_7628": "Wraper Group",
  "1_7668": "AMS",
  "I4004_1432;405_13268;405_11583": "Tasks",
  "4004_1475": "View All",
  "I4004_1463;30_31991": "Approve",
  "1_7619": "Newlands API",
  "4363_1971": "Wrapper API",
  "4004_1447": "[OM] - The system will be in maintain status at 12:00 today\n07:23 17/11/2023",
  "4004_1437": "Friday, 17 Nov 2023",
  "I4004_1429;366_12081": "Logout",
  "4019_1130": "Identity Management",
  "1_7682": "Client Dictionary",
  "I4004_1432;405_11590;405_13233;144_59614": "99+",
  "1_7658": "Alert Rules",
  "I4004_1429;366_12079": "Organization Setting",
  "1_7586": "User & Permission",
  "1_7653": "Alert",
  "I4004_1432;405_13262;405_11583": "Approval",
  "4063_1506": "Dowload SDK",
  "1_7572": "Project Overview",
  "1_7663": "API Testing",
  "1_7687": "Tasks Management",
  "I4004_1432;405_13251;405_11583": "Inbox",
  "1_7643": "Analytics",
  "1_7648": "Access Logs",
  "4089_1780": ": om-admin-test-1",
  "4019_1163": "Development",
  "4019_1165": "Staging",
  "4073_1387": "Disable all developer services currently in use by this project",
  "4019_1164": "Testing",
  "4073_1386": "Project Code",
  "I4019_1209;645_18160;30_31931": "Confirm",
  "4073_1396": "Cancel",
  "4019_1244": "Production",
  "4073_1382": "Project code",
  "4073_1401": "Delete Project",
  "4073_1373": "Delete Project",
  "I4019_1209;645_18156": "Are you sure you want to delete Project?",
  "4073_1391": "Delete this project from OM Platform and Developer Console",
  "I4019_1209;645_18159;30_32137": "Cancel",
  "I4019_1209;645_18155": "Warning",
  "4073_1394": "Project owner can restore this project within 30 days from OM Platform",
  "4073_1381": "your project will also be deleted from API Gateway and AMS",
  "4073_1384": "Project Code",
  "4019_1282": "View",
  "4030_2477": "Warning",
  "4030_2472": "Cancel",
  "I4019_1442;645_18159;30_32137": "Cancel",
  "I4082_1737;611_93387": "The Apps has been successfully updated.",
  "4030_2465": "Warning",
  "I4019_1441;623_21687": "An error has occurred, please try again or contact the administrator.",
  "4082_1768": "Inactive",
  "I4019_1440;623_21698": "Info",
  "4019_1498": "Delete",
  "4030_2461": "Confirm",
  "I4019_1438;611_93387": "The Apps has been successfully created.",
  "4005_2363": "Android",
  "4090_1415": "Are you sure you want to delete app ?",
  "4019_1511": "Edit",
  "I4019_1439;623_21593": "Warning",
  "4090_1416": "Your project will also be deleted with the app",
  "I4082_1744;611_93387": "The Apps has been successfully actived.",
  "4030_2527": "Notification",
  "4030_2450": "Confirm",
  "4005_2362": "Web",
  "4019_1589": "IOS",
  "I4019_1442;645_18160;30_31931": "Confirm",
  "4019_1506": "View",
  "4082_1770": "Active",
  "4030_2448": "Cancel",
  "4019_1587": "Web",
  "4030_2459": "Cancel",
  "4019_1592": "Active",
  "I4019_1442;645_18156": "Are you sure you want to delete Apps?",
  "4019_1588": "Android",
  "4090_1418": "Your app code",
  "4019_1593": "Inactive",
  "4005_2364": "IOS",
  "I4019_1442;645_18155": "Warning",
  "4030_2445": "Are you sure you want to active app ?",
  "4030_2469": "Are you sure you want to delete app ?",
  "4082_1769": "All",
  "4019_1515": "Delete",
  "4019_1499": "Make Active",
  "I4082_1751;611_93387": "The Apps has been successfully inactived.",
  "4030_2453": "Warning",
  "4090_1417": "Type the app code into the input below to confirm",
  "4030_2462": "Are you sure you want to inactive app ?",
  "4030_2528": "Deleted successful!",
  "4090_1422": "Cancel",
  "4030_2474": "Confirm",
  "4090_1425": "Delete App",
  "4019_1500": "Make Inactive",
  "I4082_1758;611_93387": "The Apps has been successfully deleted.",
  "4083_1778": "Security",
  "4030_2510": "Confirm",
  "4030_2498": "Are your sure to inactive wrapper?",
  "4030_2521": "Deleted successful!",
  "4030_2497": "Confirm",
  "4019_1555": "InActive",
  "4030_2484": "Cancel",
  "4030_2501": "Warning",
  "4030_2495": "Cancel",
  "4030_2505": "Are your sure to delete wrapper?",
  "4030_2520": "Notification",
  "4030_2513": "Warning",
  "4019_1549": "Web",
  "4019_1554": "Active",
  "4030_2486": "Confirm",
  "4030_2481": "Are your sure to active wrapper?",
  "4030_2489": "Warning",
  "4019_1551": "IOS",
  "4019_1550": "Android",
  "4030_2508": "Cancel",
  "4005_2669": "Upload",
  "4005_2662": "Active",
  "4005_2612": "Update",
  "4005_2617": "5",
  "4005_2650": "Description",
  "4005_2619": "Develop Console",
  "4019_1602": "Callback URL",
  "4019_1516": "App",
  "4005_2648": "Status",
  "4005_2658": "Web",
  "4019_1601": "om-admin-develop-console-app-22",
  "4019_1604": "https://...",
  "4005_2652": "Back",
  "4005_2624": "Setting",
  "4005_2654": "Next",
  "4005_2629": "API Gateway",
  "4005_2646": "Name",
  "4005_2622": "Project Overview",
  "4005_2637": "Apps",
  "4019_1599": "App Code",
  "4019_1605": "Security",
  "4005_2649": "App Icon",
  "4005_2647": "Platform",
  "4005_2666": "Enter your description",
  "4005_2641": "AMS",
  "4019_1608": "Delete",
  "4005_2656": "App 22",
  "4019_1674": "Enter your description",
  "4019_1648": "Name",
  "4019_1621": "Develop Console",
  "4019_1651": "Platform",
  "4019_1654": "Description",
  "4019_1662": "om-admin-develop-console-app-22",
  "4019_1652": "Status",
  "4019_1619": "5",
  "4019_1639": "Apps",
  "4019_1650": "Callback URL",
  "4019_1624": "Project Overview",
  "4019_1664": "https://...",
  "4019_1631": "API Gateway",
  "4019_1670": "Active",
  "4019_1613": "Update App",
  "4019_1649": "App Code",
  "4019_1653": "App Icon",
  "4019_1681": "Security",
  "4019_1703": "Preview",
  "4019_1656": "Back",
  "4019_1643": "AMS",
  "4019_1666": "Web",
  "4019_1677": "Upload",
  "4019_1626": "Setting",
  "4019_1658": "Update",
  "4019_1704": "-",
  "4019_1660": "App 22",
  "4019_1685": "Delete",
  "4019_1825": "Project Overview",
  "4019_1827": "Setting",
  "4019_1822": "Develop Console",
  "4019_1857": "Back",
  "4019_1832": "API Gateway",
  "4019_1820": "5",
  "4019_1855": "Description",
  "4019_1865": "https://...",
  "4019_1814": "Review App",
  "4019_1849": "Name",
  "4019_1854": "App Icon",
  "4019_1871": "Active",
  "4019_1861": "App 22",
  "4019_1882": "Security",
  "4019_1875": "Enter your description",
  "4019_1844": "AMS",
  "4019_1852": "Platform",
  "4019_1840": "Apps",
  "4019_1851": "Callback URL",
  "4019_1853": "Status",
  "4019_1878": "Upload",
  "4019_1867": "Web",
  "4019_1886": "Delete",
  "4021_1982": "Back",
  "4021_1975": "How to use",
  "4021_1927": "API Gateway",
  "4021_1968": "Security",
  "4021_1922": "Setting",
  "4021_1909": "App Security",
  "4021_1969": "App Auth Code",
  "4021_1939": "AMS",
  "4021_1967": "/",
  "4021_1935": "Apps",
  "4021_1971": "1",
  "4021_1977": "Guide how to use App Auth Code",
  "4021_1915": "5",
  "4021_1917": "Develop Console",
  "4021_1966": "Apps",
  "4021_1920": "Project Overview",
  "4094_1817": "Inactive",
  "I4021_2113;611_93387": "The Wrapper API has been successfully updated.",
  "4093_1795": "Delete",
  "4093_1793": "Make Active",
  "I4021_2117;645_18156": "Are you sure you want to delete Wrapper API?",
  "4094_1816": "Active",
  "4021_2083": "Active",
  "I4093_1796;611_93387": "The Wrapper API has been successfully created.",
  "4021_2082": "All",
  "I4021_2117;645_18160;30_31931": "Confirm",
  "4093_1794": "Make Inactive",
  "4021_2132": "Delete",
  "I4021_2117;645_18159;30_32137": "Cancel",
  "4021_2128": "Edit",
  "I4093_1803;611_93387": "The Wrapper API has been successfully deleted.",
  "4021_2175": "Coppy",
  "4021_2123": "View",
  "I4021_2115;623_21698": "Info",
  "I4021_2117;645_18155": "Warning",
  "I4021_2114;623_21593": "Warning",
  "I4021_2116;623_21687": "An error has occurred, please try again or contact the administrator.",
  "4021_2084": "Inactive",
  "4030_2553": "BODY",
  "4030_2552": "HEADER",
  "4030_2424": "Are your sure to inactive wrapper?",
  "4030_2535": "Deleted successful!",
  "4030_2441": "Warning",
  "4030_2437": "Confirm",
  "4030_2435": "Cancel",
  "4005_3471": "Confirm",
  "4108_2036": "All",
  "4030_2540": "PUT",
  "4030_2427": "Warning",
  "4030_2545": "DELETE",
  "4108_2035": "Inactive",
  "4030_2534": "Notification",
  "4030_2423": "Confirm",
  "4030_2548": "EQUAL",
  "4030_2543": "Active",
  "4005_3469": "Cancel",
  "4005_3475": "Warning",
  "4030_2539": "POST",
  "4108_2034": "Active",
  "4030_2538": "GET",
  "4030_2432": "Are your sure to delete wrapper?",
  "4005_3465": "Are your sure to active wrapper?",
  "4030_2421": "Cancel",
  "4030_2544": "InActive",
  "4030_2598": "Single",
  "4030_2577": "Group",
  "4030_2559": "Description",
  "4030_2972": "PUT",
  "4030_2652": "Service",
  "4030_2567": "/sport/country/import",
  "4030_2955": "API Gateway",
  "4030_2867": "Apps",
  "4030_2581": "Enter description.",
  "4030_2949": "Update Wrapper API",
  "4030_2850": "Develop Console",
  "4030_2557": "Status",
  "4030_2646": "Pre",
  "4030_2855": "Setting",
  "4030_2916": "Dowload SDK",
  "4030_2650": "-",
  "4030_2648": "Filtering",
  "4030_2956": "/",
  "4030_2976": "DELETE",
  "4030_2871": "AMS",
  "4030_2654": "Resource",
  "4030_2896": "Analytics",
  "4030_2644": "Add more",
  "4030_2602": "Multiple",
  "4030_2593": "Target",
  "4030_2656": "Enter service...",
  "4030_2569": "GET",
  "4030_2585": "Relative",
  "4030_2565": "Sports 22",
  "4030_2860": "API Gateway",
  "4030_2651": "Filtering",
  "4030_2912": "Alert Rules",
  "4030_2558": "Groups",
  "4030_2640": "Add more",
  "4030_2573": "ACTIVE",
  "4030_2660": "/sport/football/country",
  "4030_2653": "Service",
  "4030_2848": "5",
  "4030_2649": "Post",
  "4030_2888": "Traffic Control",
  "4030_2555": "Consumer Endpoint",
  "4030_2563": "Next",
  "4030_2901": "Access Logs",
  "4030_2880": "Wraper API",
  "4030_2921": "API Testing",
  "4030_2884": "Wraper Group",
  "4030_2908": "Alert",
  "4030_2968": "POST",
  "4030_2647": "-",
  "4030_2556": "Method",
  "4030_2957": "Wrapper API",
  "4030_2853": "Project Overview",
  "4030_2892": "Access Limit",
  "4030_2554": "Name",
  "4030_2561": "Back",
  "4030_2591": "Enable IDM Auth",
  "4030_3137": "Access Logs",
  "4030_3040": "Single",
  "4030_3079": "API Gateway",
  "4030_2989": "Update",
  "4030_3058": "Filtering",
  "4030_3062": "Filtering",
  "4030_3157": "API Testing",
  "4030_2981": "Consumer Endpoint",
  "4030_3035": "Target",
  "4030_3120": "Wraper Group",
  "4030_3073": "Update Wrapper API",
  "4030_3091": "Setting",
  "4030_3019": "Group",
  "4030_3144": "Alert",
  "4030_3065": "Resource",
  "4030_3063": "Service",
  "4030_3148": "Alert Rules",
  "4030_3044": "Multiple",
  "4030_3001": "POST",
  "4030_2991": "Sports 22",
  "4030_3005": "PUT",
  "4030_2982": "Method",
  "4030_3050": "Add more",
  "4030_3015": "ACTIVE",
  "4030_2993": "/sport/country/import",
  "4030_3071": "/sport/football/country",
  "4030_3152": "Dowload SDK",
  "4030_3128": "Access Limit",
  "4030_3084": "5",
  "4030_3057": "-",
  "4030_3027": "Relative",
  "4030_3067": "Enter service...",
  "4030_2997": "GET",
  "4030_2985": "Description",
  "4030_2983": "Status",
  "4030_3009": "DELETE",
  "4030_2984": "Groups",
  "4030_3023": "Enter description.",
  "4030_2987": "Back",
  "4030_3086": "Develop Console",
  "4030_3183": "Preview",
  "4030_3060": "Post",
  "4030_3107": "AMS",
  "4030_3064": "Service",
  "4030_3056": "Pre",
  "4030_3184": "-",
  "4030_3116": "Wraper API",
  "4030_3054": "Add more",
  "4030_2980": "Name",
  "4030_3124": "Traffic Control",
  "4030_3061": "-",
  "4030_3089": "Project Overview",
  "4030_3080": "/",
  "4030_3096": "API Gateway",
  "4030_3033": "Enable IDM Auth",
  "4030_3103": "Apps",
  "4030_3081": "Wrapper API",
  "4030_3132": "Analytics",
  "4030_3204": "Are your sure to inactive wrapper?",
  "4030_3240": "HEADER",
  "4030_3227": "Deleted successful!",
  "4030_3237": "InActive",
  "4030_3201": "Cancel",
  "4030_3203": "Confirm",
  "4030_3226": "Notification",
  "4030_3219": "Warning",
  "4030_3244": "EQUAL",
  "4030_3236": "Active",
  "4030_3192": "Confirm",
  "4030_3195": "Warning",
  "4030_3233": "DELETE",
  "4030_3230": "GET",
  "4030_3231": "POST",
  "4030_3241": "BODY",
  "4030_3207": "Warning",
  "4030_3190": "Cancel",
  "4030_3216": "Confirm",
  "4030_3187": "Are your sure to active wrapper?",
  "4030_3232": "PUT",
  "4030_3214": "Cancel",
  "4030_3211": "Are your sure to delete wrapper?",
  "4030_3322": "-",
  "4030_3315": "Add more",
  "4030_3300": "Target",
  "4030_3305": "Single",
  "4030_3252": "Back",
  "4030_3250": "Description",
  "4030_3247": "Method",
  "4030_3258": "/sport/country/import",
  "4030_3370": "Apps",
  "4030_3256": "Sports 22",
  "4030_3288": "Enter description.",
  "4030_3280": "ACTIVE",
  "4030_3353": "Develop Console",
  "4030_3411": "Alert",
  "4030_3404": "Access Logs",
  "4030_3274": "DELETE",
  "4030_3323": "Filtering",
  "4030_3358": "Setting",
  "4030_3419": "Dowload SDK",
  "4030_3248": "Status",
  "4030_3348": "Wrapper API",
  "4030_3336": "/sport/football/country",
  "4030_3328": "Service",
  "4030_3347": "/",
  "4030_3363": "API Gateway",
  "4030_3399": "Analytics",
  "4030_3298": "Enable IDM Auth",
  "4030_3309": "Multiple",
  "4030_3330": "Resource",
  "4030_3387": "Wraper Group",
  "4030_3391": "Traffic Control",
  "4030_3270": "PUT",
  "4030_3262": "GET",
  "4030_3415": "Alert Rules",
  "4030_3424": "API Testing",
  "4030_3245": "Name",
  "4030_3327": "Filtering",
  "4030_3332": "Enter service...",
  "4030_3383": "Wraper API",
  "4030_3249": "Groups",
  "4030_3321": "Pre",
  "4030_3292": "Relative",
  "4030_3325": "Post",
  "4030_3284": "Group",
  "4030_3326": "-",
  "4030_3329": "Service",
  "4030_3374": "AMS",
  "4030_3266": "POST",
  "4030_3246": "Consumer Endpoint",
  "4030_3319": "Add more",
  "4030_3395": "Access Limit",
  "4030_3346": "API Gateway",
  "4030_3338": "Review Wrapper API",
  "4030_3356": "Project Overview",
  "4030_3351": "5",
  "4030_3725": "Alert",
  "4030_3701": "Wraper Group",
  "4030_3649": "Description",
  "4030_3653": "Next",
  "4030_3705": "Traffic Control",
  "4030_3783": "Wrapper Group",
  "4030_3655": "Wrapper Group 33",
  "4030_3684": "Apps",
  "4030_3677": "API Gateway",
  "4030_3670": "Project Overview",
  "4030_3657": "Sports 22",
  "4030_3781": "API Gateway",
  "4030_3782": "/",
  "4030_3667": "Develop Console",
  "4030_3733": "Dowload SDK",
  "4030_3647": "Name",
  "4030_3672": "Setting",
  "4030_3738": "API Testing",
  "4030_3718": "Access Logs",
  "4030_3775": "Update Wrapper Group",
  "4030_3648": "Wrappers",
  "4030_3729": "Alert Rules",
  "4030_3709": "Access Limit",
  "4030_3661": "Enter description.",
  "4030_3713": "Analytics",
  "4030_3651": "Back",
  "4030_3665": "5",
  "4030_3792": "Sports 22",
  "4030_3688": "AMS",
  "4030_3697": "Wraper API",
  "4030_3909": "API Gateway",
  "4030_3797": "Wrappers",
  "4030_3865": "Access Limit",
  "4030_3828": "Setting",
  "4030_3840": "Apps",
  "4030_3833": "API Gateway",
  "4030_3881": "Alert",
  "4030_3796": "Name",
  "4030_3800": "Back",
  "4030_3826": "Project Overview",
  "4030_3821": "5",
  "4030_3818": "Enter description.",
  "4030_3910": "/",
  "4030_3804": "Wrapper Group 33",
  "4030_3903": "Preview Wrapper Group ",
  "4030_3798": "Description",
  "4030_3874": "Access Logs",
  "4030_3861": "Traffic Control",
  "4030_3911": "Wrapper Group",
  "4030_3808": "Sports 22",
  "4030_3889": "Dowload SDK",
  "4030_3857": "Wraper Group",
  "4030_3812": "Sports 22",
  "4030_3823": "Develop Console",
  "4030_3844": "AMS",
  "4030_3885": "Alert Rules",
  "4030_3869": "Analytics",
  "4030_3802": "Update",
  "4030_3894": "API Testing",
  "4030_3853": "Wraper API",
  "4030_4058": "Alert",
  "4030_4030": "Wraper API",
  "4030_4051": "Access Logs",
  "4030_4080": "Review Wrapper Group",
  "4030_4003": "Project Overview",
  "4030_4046": "Analytics",
  "4030_3973": "Name",
  "4030_4005": "Setting",
  "4030_4021": "AMS",
  "4030_4087": "/",
  "4030_3974": "Wrappers",
  "4030_4010": "API Gateway",
  "4030_3975": "Description",
  "4030_4000": "Develop Console",
  "4030_4086": "API Gateway",
  "4030_4066": "Dowload SDK",
  "4030_4062": "Alert Rules",
  "4030_4042": "Access Limit",
  "4030_4034": "Wraper Group",
  "4030_3995": "Enter description.",
  "4030_4071": "API Testing",
  "4030_4088": "Wrapper Group",
  "4030_3985": "Sports 22",
  "4030_3981": "Wrapper Group 33",
  "4030_3998": "5",
  "4030_3989": "Sports 22",
  "4030_4017": "Apps",
  "4030_4038": "Traffic Control",
  "4030_3977": "Back",
  "I4111_1747;611_93387": "The Traffic Control has been successfully created.",
  "4111_1793": "Make Active",
  "I4111_1758;611_93387": "The Traffic Control has been successfully deleted.",
  "I4030_4162;645_18159;30_32137": "Cancel",
  "I4030_4160;623_21698": "Info",
  "I4030_4162;645_18160;30_31931": "Confirm",
  "4030_4168": "View",
  "I4030_4162;645_18155": "Warning",
  "4111_1797": "Delete",
  "I4030_4159;623_21593": "Warning",
  "4111_1799": "Make Inactive",
  "I4030_4162;645_18156": "Are you sure you want to delete Traffic Control?",
  "I4030_4161;623_21687": "An error has occurred, please try again or contact the administrator.",
  "4030_4178": "Edit",
  "I4030_4158;611_93387": "The Traffic Control has been successfully updated.",
  "4030_4182": "Delete",
  "4030_4384": "Analytics",
  "4030_4336": "5",
  "4030_4389": "Access Logs",
  "4030_4348": "API Gateway",
  "4030_4428": "API Gateway",
  "4030_4341": "Project Overview",
  "4030_4321": "Next",
  "4030_4319": "Back",
  "4030_4429": "/",
  "4030_4338": "Develop Console",
  "4030_4372": "Wraper Group",
  "4030_4316": "Requested Tokens",
  "4030_4368": "Wraper API",
  "4030_4314": "Replenish Rate",
  "4030_4331": "Sports 22",
  "4030_4317": "Wrapper API",
  "4030_4323": "test",
  "4030_4422": "Update Traffic Control",
  "4030_4359": "AMS",
  "4030_4329": "1",
  "4030_4396": "Alert",
  "4030_4315": "Burst Capacity",
  "4030_4313": "Name",
  "4030_4400": "Alert Rules",
  "4030_4404": "Dowload SDK",
  "4030_4380": "Access Limit",
  "4030_4430": "Traffic Control",
  "4030_4343": "Setting",
  "4030_4409": "API Testing",
  "4030_4327": "3",
  "4030_4376": "Traffic Control",
  "4030_4355": "Apps",
  "4030_4325": "2",
  "4030_4451": "Burst Capacity",
  "4030_4512": "Traffic Control",
  "4030_4472": "5",
  "4030_4508": "Wraper Group",
  "4030_4467": "Sports 22",
  "4030_4525": "Access Logs",
  "4030_4463": "3",
  "4030_4562": "Traffic Control",
  "4030_4450": "Replenish Rate",
  "4030_4536": "Alert Rules",
  "4030_4520": "Analytics",
  "4030_4449": "Name",
  "4030_4457": "Update",
  "4030_4581": "Preview",
  "4030_4516": "Access Limit",
  "4030_4461": "2",
  "4030_4452": "Requested Tokens",
  "4030_4504": "Wraper API",
  "4030_4474": "Develop Console",
  "4030_4540": "Dowload SDK",
  "4030_4465": "1",
  "4030_4459": "test",
  "4030_4455": "Back",
  "4030_4532": "Alert",
  "4030_4561": "/",
  "4030_4479": "Setting",
  "4030_4560": "API Gateway",
  "4030_4477": "Project Overview",
  "4030_4554": "Update Traffic Control",
  "4030_4545": "API Testing",
  "4030_4495": "AMS",
  "4030_4491": "Apps",
  "4030_4453": "Wrapper API",
  "4030_4484": "API Gateway",
  "4030_4650": "Develop Console",
  "4030_4688": "Traffic Control",
  "4030_4667": "Apps",
  "4030_4635": "test",
  "4030_4712": "Alert Rules",
  "4030_4660": "API Gateway",
  "4030_4648": "5",
  "4030_4671": "AMS",
  "4030_4625": "Name",
  "4030_4738": "/",
  "4030_4626": "Replenish Rate",
  "4030_4684": "Wraper Group",
  "4030_4643": "Sports 22",
  "4030_4730": "Review Traffic Control",
  "4030_4708": "Alert",
  "4030_4653": "Project Overview",
  "4030_4692": "Access Limit",
  "4030_4631": "Back",
  "4030_4716": "Dowload SDK",
  "4030_4739": "Traffic Control",
  "4030_4721": "API Testing",
  "4030_4655": "Setting",
  "4030_4637": "2",
  "4030_4696": "Analytics",
  "4030_4680": "Wraper API",
  "4030_4627": "Burst Capacity",
  "4030_4639": "3",
  "4030_4628": "Requested Tokens",
  "4030_4629": "Wrapper API",
  "4030_4641": "1",
  "4030_4737": "API Gateway",
  "4030_4701": "Access Logs",
  "4007_5969": "Traffic Control",
  "4007_5952": "AMS",
  "4007_5920": "Start Date",
  "4007_5914": "App ID...",
  "4007_5831": "Action",
  "4007_5929": "5",
  "4007_5934": "Project Overview",
  "4007_5993": "Alert Rules",
  "4007_5876": "0de63b09-cdc0-4ed2-a51f-e5e72f405889",
  "4007_5918": "Request ID...",
  "4038_6335": "Access Log",
  "4007_5871": "200",
  "4038_6345": "10  /",
  "4038_6339": "1",
  "4007_5847": "Created Date",
  "4007_5860": "INFO",
  "4038_6352": "10",
  "4007_6002": "API Testing",
  "4007_5835": "Message",
  "4007_5839": "Method",
  "4007_5989": "Alert",
  "4038_6346": "page",
  "4038_6333": "API Gateway ",
  "4038_6351": "1",
  "4007_5904": "Enter Level",
  "4038_6355": "of",
  "4038_6350": "Show",
  "4007_5845": "Status",
  "4007_5866": "GET",
  "4007_5973": "Access Limit",
  "4007_5874": "13:45:04 01-02-2024",
  "4038_6329": "Access Logs",
  "4007_5961": "Wraper API",
  "4007_5931": "Develop Console",
  "4007_5841": "App ID",
  "4007_5948": "Apps",
  "4007_5923": "End Date",
  "4007_5941": "API Gateway",
  "4007_5837": "Request ID",
  "4007_5912": "Global ID...",
  "4007_5824": "Global ID",
  "4007_5982": "Access Logs",
  "4007_5977": "Analytics",
  "4007_5908": "Method...",
  "4007_5900": "Select Status",
  "4038_6334": "/ ",
  "4038_6354": "to",
  "4007_5916": "Messages...",
  "4038_6356": "results",
  "4007_5997": "Dowload SDK",
  "4007_5833": "Level",
  "4007_5862": "OK",
  "4007_5936": "Setting",
  "4038_6353": "38",
  "4007_5864": "a5b14052",
  "4007_5965": "Wraper Group",
  "4007_5843": "Visit No",
  "4038_6425": "PUT",
  "4038_6431": "WARN",
  "I4038_6358;611_93387": "The Access Logs has been successfully updated.",
  "4038_6439": "300 - 399",
  "4038_6441": "500 - 599",
  "4038_6424": "POST",
  "4038_6418": "ALL",
  "4038_6430": "INFO",
  "I4038_6359;623_21593": "Warning",
  "4038_6437": "100 - 199",
  "4038_6433": "FATAL",
  "I4038_6357;645_18156": "Are you sure you want to delete Access Logs?",
  "4038_6436": "ALL",
  "I4038_6357;645_18159;30_32137": "Cancel",
  "4038_6438": "200 - 299",
  "4038_6419": "GET",
  "4038_6429": "ALL",
  "I4038_6360;623_21698": "Info",
  "4038_6432": "ERROR",
  "I4038_6357;645_18160;30_31931": "Confirm",
  "4038_6367": "View",
  "I4038_6357;645_18155": "Warning",
  "4038_6426": "DELETE",
  "4038_6440": "400 - 499",
  "I4038_6361;623_21687": "An error has occurred, please try again or contact the administrator.",
  "4038_6737": "InActive",
  "4134_2513": "Delete",
  "4038_6790": "API Gateway",
  "4038_6792": "Common Service",
  "I4038_6821;623_21698": "Info",
  "4038_6889": "Warning",
  "4038_6886": "Are your sure to inactive Alert Rule?",
  "4134_2515": "Make Inactive",
  "193_7979": "Confirm",
  "4038_6788": "Control Plane",
  "4038_6784": "HTTP MESSAGE",
  "4038_6897": "Cancel",
  "193_7977": "Cancel",
  "4038_6736": "Active",
  "4038_6908": "ACTIVE",
  "4038_6828": "View",
  "2001_1461": "Are your sure to active Alert Rule?",
  "4038_6833": "Edit",
  "I4038_6819;611_93387": "The Access Limit has been successfully updated.",
  "4038_6787": "IDM",
  "193_7983": "Warning",
  "I4038_6820;623_21593": "Warning",
  "4038_6791": "Notification",
  "I4038_6822;623_21687": "An error has occurred, please try again or contact the administrator.",
  "4038_6914": "ALL",
  "4038_6909": "InActive",
  "4038_6885": "Confirm",
  "4038_6915": "STATUS CODE",
  "4038_6837": "Delete",
  "4038_6883": "Cancel",
  "4038_6899": "Confirm",
  "4038_6903": "Warning",
  "4038_6795": "EQUAL",
  "4038_6916": "HTTP MESSAGE",
  "4038_6789": "AMS",
  "4038_6783": "STATUS CODE",
  "4134_2514": "Make Active",
  "4038_6907": "ALL",
  "4038_6893": "Are your sure to delete Alert Rule?",
  "4007_6935": "Cancel",
  "I4038_6481;623_21593": "Warning",
  "4038_6486": "All",
  "I4038_6483;623_21687": "An error has occurred, please try again or contact the administrator.",
  "4007_6937": "Warning",
  "4130_2349": "Unresolved",
  "4130_2337": "Unresolve",
  "I4038_6480;611_93387": "The Access Logs has been successfully updated.",
  "4038_6488": "Unresolved",
  "4007_6933": "Comfirm",
  "4130_2336": "Resolve",
  "4130_2348": "Resolved",
  "4038_6487": "Resolved",
  "I4038_6482;623_21698": "Info",
  "4007_6936": 'Are you sure to change the status from "Unresolved" to "Resolved"?',
  "4115_2253": "Make Active",
  "4030_5549": "View",
  "I4030_4802;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I4030_4801;623_21698": "Info",
  "4115_2270": "Inactive",
  "4115_2264": "Active",
  "I4030_4799;611_93387": "The Access Limit has been successfully updated.",
  "I4030_4800;623_21593": "Warning",
  "4115_2257": "Delete",
  "I4030_4783;645_18159;30_32137": "Cancel",
  "I4114_2067;611_93387": "The Access Limit has been successfully created.",
  "I4030_4783;645_18156": "Are you sure you want to delete Access Limit?",
  "I4030_4783;645_18160;30_31931": "Confirm",
  "4115_2259": "Make Inactive",
  "I4114_2074;611_93387": "The Access Limit has been successfully delated.",
  "4030_5554": "Edit",
  "4117_2291": "Inactive",
  "4117_2289": "Active",
  "4117_2293": "All",
  "4030_5558": "Delete",
  "I4030_4783;645_18155": "Warning",
  "4038_6926": "Back",
  "4038_6920": "Resource",
  "4007_7205": "Alert Rules",
  "4038_6917": "Name",
  "4007_7177": "Wraper Group",
  "4038_6954": "dung.nguyen+200@netko",
  "4038_6923": "Status",
  "4007_7143": "Develop Console",
  "4038_6934": "STATUS CODE ",
  "4038_6938": "AMS",
  "4038_6932": "500",
  "4007_7194": "Access Logs",
  "4038_6973": "Alert Rules",
  "4038_6928": "Next",
  "4007_7160": "Apps",
  "4038_6972": "/ ",
  "4007_7209": "Dowload SDK",
  "4007_7146": "Project Overview",
  "4007_7181": "Traffic Control",
  "4038_6921": "Condition",
  "4038_6942": "EQUAL",
  "4007_7201": "Alert",
  "4007_7148": "Setting",
  "4038_6971": "API Gateway ",
  "4038_6922": "Value",
  "4038_6950": "ACTIVE",
  "4038_6965": "Update Alert Rules",
  "4038_6919": "Type",
  "4038_6957": "Rule Condition",
  "4038_6918": "Message",
  "4038_6930": "Error",
  "4007_7214": "API Testing",
  "4007_7189": "Analytics",
  "4038_6924": "Notification",
  "4007_7153": "API Gateway",
  "4038_6946": "500",
  "4007_7185": "Access Limit",
  "4007_7141": "5",
  "4007_7164": "AMS",
  "4007_7173": "Wraper API",
  "4038_7097": "Rule Condition",
  "4038_7008": "Wraper API",
  "4038_7068": "Update",
  "4038_6976": "5",
  "4038_7060": "Resource",
  "4038_7044": "Dowload SDK",
  "4038_7072": "500",
  "4038_7063": "Status",
  "4038_7105": "API Gateway ",
  "4038_6995": "Apps",
  "4038_7049": "API Testing",
  "4038_6988": "API Gateway",
  "4038_7061": "Condition",
  "4038_7066": "Back",
  "4038_7086": "500",
  "4038_7126": "Preview",
  "4038_6983": "Setting",
  "4038_7106": "/ ",
  "4038_7107": "Alert Rules",
  "4038_7090": "ACTIVE",
  "4038_7057": "Name",
  "4038_7062": "Value",
  "4038_7070": "Error",
  "4038_7029": "Access Logs",
  "4038_6999": "AMS",
  "4038_7078": "AMS",
  "4038_6978": "Develop Console",
  "4038_7082": "EQUAL",
  "4038_7074": "STATUS CODE ",
  "4038_7059": "Type",
  "4038_7040": "Alert Rules",
  "4038_7094": "dung.nguyen+200@netko",
  "4038_7020": "Access Limit",
  "4038_7064": "Notification",
  "4038_7036": "Alert",
  "4038_7058": "Message",
  "4038_7099": "Update Alert Rules",
  "4038_7016": "Traffic Control",
  "4038_6981": "Project Overview",
  "4038_7012": "Wraper Group",
  "4038_7024": "Analytics",
  "4030_5476": "Notification",
  "4030_5477": "Deleted successful!",
  "4030_5469": "Warning",
  "4030_4878": "InActive",
  "4030_4924": "SINGLE",
  "4030_4910": "Confirm",
  "4030_4901": "Warning",
  "4030_4895": "Cancel",
  "4030_4921": "Deleted successful!",
  "4030_5430": "InActive",
  "4030_5440": "Cancel",
  "4030_5461": "Are your sure to delete wrapper?",
  "4030_5466": "Confirm",
  "4030_5451": "Cancel",
  "4030_4905": "Are your sure to delete wrapper?",
  "4030_4889": "Warning",
  "4030_4897": "Confirm",
  "4030_4898": "Are your sure to inactive wrapper?",
  "4007_7567": "App Callback URL",
  "4030_4881": "Are your sure to active wrapper?",
  "4030_5434": "RANGE",
  "4030_5433": "SINGLE",
  "4030_4925": "RANGE",
  "4030_5454": "Are your sure to inactive wrapper?",
  "4030_5429": "Active",
  "4030_5445": "Warning",
  "4030_4877": "Active",
  "4030_5442": "Confirm",
  "4030_5464": "Cancel",
  "4007_7569": "Please fill your App Callback URL! (https://example.com)",
  "4030_4913": "Warning",
  "4030_5457": "Warning",
  "4030_5453": "Confirm",
  "4007_7568": "Callback URL",
  "4030_5437": "Are your sure to active wrapper?",
  "4030_4908": "Cancel",
  "4030_4920": "Notification",
  "4030_4884": "Cancel",
  "4030_4886": "Confirm",
  "4030_5074": "/",
  "4030_5056": "Dowload SDK",
  "4030_5041": "Access Logs",
  "4030_4988": "5",
  "4030_4933": "Name A",
  "4030_5007": "Apps",
  "4030_5036": "Analytics",
  "4030_4968": "Wrapper API Group",
  "4030_5020": "Wraper API",
  "4030_4963": "Time Range To",
  "4030_4980": "Add more",
  "4030_4926": "Name",
  "4030_4993": "Project Overview",
  "4030_5000": "API Gateway",
  "4030_5075": "Access Limit",
  "4030_4995": "Setting",
  "4030_4967": "Wrapper API",
  "4030_5048": "Alert",
  "4030_4946": "10.10.10.10",
  "4030_5052": "Alert Rules",
  "4030_4957": "Add more",
  "4007_7734": "App Callback URL",
  "4030_4960": "Time Range To",
  "4007_7736": "Please fill your App Callback URL! (https://example.com)",
  "4030_4972": "Wrapper API Group",
  "4030_4976": "Apply To",
  "4030_4944": "Type",
  "4030_5073": "API Gateway",
  "4030_5028": "Traffic Control",
  "4030_4935": "ACTIVE",
  "4030_4970": "Wrapper API",
  "4030_5067": "Update Access Limit",
  "4030_4927": "Status",
  "4030_4952": "Allowlist",
  "4030_4943": "IP",
  "4030_4965": "Time Range From",
  "4030_5024": "Wraper Group",
  "4030_5011": "AMS",
  "4030_4985": "Time Range",
  "4030_5032": "Access Limit",
  "4007_7735": "Callback URL",
  "4030_4961": "Time Range From",
  "4030_4929": "Back",
  "4030_4953": "List of IP addresses will be allowed to access the API",
  "4030_5061": "API Testing",
  "4030_4931": "Next",
  "4030_4948": "SINGLE",
  "4030_4990": "Develop Console",
  "4030_5130": "Time Range From",
  "4030_5201": "Analytics",
  "4030_5197": "Access Limit",
  "4030_5172": "Apps",
  "4030_5110": "IP",
  "4030_5213": "Alert",
  "4030_5176": "AMS",
  "4030_5232": "Preview",
  "4007_7744": "Please fill your App Callback URL! (https://example.com)",
  "4007_7742": "App Callback URL",
  "4030_5165": "API Gateway",
  "4030_5193": "Traffic Control",
  "4030_5102": "Update",
  "4030_5150": "Time Range",
  "4030_5137": "Wrapper API Group",
  "4030_5217": "Alert Rules",
  "4030_5125": "Time Range To",
  "4030_5100": "Back",
  "4030_5155": "Develop Console",
  "4030_5158": "Project Overview",
  "4030_5113": "10.10.10.10",
  "4030_5189": "Wraper Group",
  "4030_5104": "Name A",
  "4030_5141": "Apply To",
  "4030_5098": "Status",
  "4030_5119": "Allowlist",
  "4030_5128": "Time Range To",
  "4030_5115": "SINGLE",
  "4030_5132": "Wrapper API",
  "4030_5238": "API Gateway",
  "4030_5185": "Wraper API",
  "4030_5160": "Setting",
  "4030_5106": "ACTIVE",
  "4030_5111": "Type",
  "4030_5145": "Add more",
  "4030_5226": "API Testing",
  "4030_5240": "Access Limit",
  "4030_5133": "Wrapper API Group",
  "4030_5120": "List of IP addresses will be allowed to access the API",
  "4030_5124": "Add more",
  "4007_7743": "Callback URL",
  "4030_5221": "Dowload SDK",
  "4030_5153": "5",
  "4030_5206": "Access Logs",
  "4030_5126": "Time Range From",
  "4030_5239": "/",
  "4030_5097": "Name",
  "4030_5135": "Wrapper API",
  "4110_1462": "Access Limit",
  "4030_5512": "Are your sure to delete wrapper?",
  "4030_5520": "Warning",
  "4007_7746": "App Callback URL",
  "4030_5502": "Cancel",
  "4030_5496": "Warning",
  "4030_5505": "Are your sure to inactive wrapper?",
  "4030_5488": "Are your sure to active wrapper?",
  "4030_5515": "Cancel",
  "4030_5504": "Confirm",
  "4030_5484": "SINGLE",
  "4030_5491": "Cancel",
  "4007_7748": "Please fill your App Callback URL! (https://example.com)",
  "4030_5528": "Deleted successful!",
  "4030_5508": "Warning",
  "4030_5517": "Confirm",
  "4030_5493": "Confirm",
  "4030_5481": "InActive",
  "4030_5485": "RANGE",
  "4030_5527": "Notification",
  "4030_5480": "Active",
  "4007_7747": "Callback URL",
  "4030_6300": "2",
  "4030_6267": "/api/v1/internal/analysis/avg-duration-per-hour",
  "4030_6152": "Analytics",
  "4030_6143": "API Testing",
  "4030_6255": "1",
  "4030_6226": "8",
  "4030_6123": "Access Logs",
  "4030_6199": "Total API Requests",
  "4030_6211": "Response Time Min",
  "4030_6228": "TOP 10 APIs Time Spent",
  "4030_6315": "143",
  "4030_6297": "1",
  "4030_6138": "Dowload SDK",
  "4030_6202": "792",
  "4030_6205": "Response Time Max",
  "4030_6318": "107",
  "4030_6303": "API",
  "4126_2328": "End Date",
  "4030_6113": "Access Limit",
  "4030_6190": "Name",
  "4030_6196": "Value",
  "4030_6118": "Analytics",
  "4030_6158": "Analytics",
  "4030_6075": "Project Overview",
  "4030_6249": "Path",
  "4007_7915": "Create Access Limit",
  "4030_6077": "Setting",
  "4030_6134": "Alert Rules",
  "4030_6261": "313703",
  "4030_6270": "238922",
  "4030_6109": "Traffic Control",
  "4030_6264": "2",
  "4030_6157": "/ ",
  "4030_6082": "API Gateway",
  "4030_6070": "5",
  "4030_6214": "5",
  "4030_6093": "AMS",
  "4030_6130": "Alert",
  "4030_6312": "Count",
  "4030_6089": "Apps",
  "4030_6320": "TOP 10 APIs Visit Spent",
  "4030_6245": "No.",
  "4030_6294": "No.",
  "4030_6252": "Millisecond",
  "4030_6156": "API Gateway ",
  "4030_6220": "8",
  "4030_6309": "/api-control-plane/api/v1/internal/permissions/",
  "4126_2325": "Start Date",
  "4030_6208": "313703",
  "4030_6072": "Develop Console",
  "4030_6306": "/api/v1/internal/analysis/count",
  "4030_6105": "Wraper Group",
  "4030_6217": "Active Device",
  "4030_6258": "/api/v1/internal/analysis/avg-duration-per-hour",
  "4030_6189": "Summary",
  "4030_6223": "Total Device",
  "4030_6101": "Wraper API",
  "4038_7323": "Tax-Code",
  "4038_7448": "Administrator",
  "4038_7373": "Country",
  "4038_7440": "User Roles",
  "4038_7421": "TOP",
  "4038_7434": "Policies",
  "4038_7409": "Update Organization",
  "4038_7319": "Email",
  "4038_7320": "Address",
  "4038_7318": "Organization",
  "4038_7460": "Applications",
  "4038_7326": "Root Password",
  "4038_7389": "+84",
  "4038_7371": "85015",
  "4038_7347": "IDM Quadruple",
  "4038_7399": "Upload",
  "4038_7353": "",
  "4038_7333": "Access Limit",
  "4038_7464": "Vehicles",
  "4038_7367": "Phoenix",
  "4038_7418": "IDM Portal",
  "4038_7324": "Organization Type",
  "4038_7349": "freune@gmail.com",
  "4038_7385": "Service Provider 2B",
  "4038_7468": "Identity Provider",
  "4038_7351": "2915 N 21st Ave",
  "4038_7325": "Phone",
  "4038_7377": "imd-quadruple",
  "4038_7402": "image 3",
  "4038_7416": "5",
  "4038_7359": "",
  "4038_7327": "Confirm Root Password",
  "4038_7337": "Admin Password Policy",
  "4038_7383": "M Smith",
  "4038_7322": "Representative",
  "4038_7405": "/",
  "4038_7430": "Organization",
  "4038_7406": "Update Organization",
  "4038_7381": "tax 111",
  "4038_7423": "Permission",
  "4038_7404": "Organization",
  "4038_7444": "Resources",
  "4038_7379": "Aaron",
  "4038_7329": "Save",
  "4038_7396": "Logo",
  "4038_7341": "End-User Password Policy",
  "4038_7321": "Organization Code",
  "4038_7345": "OTP Management",
  "4038_7393": "1234567841",
  "4038_7369": "22",
  "4038_7365": "Phoenix",
  "4038_7454": "User",
  "4038_7498": "Service Provider 2C",
  "I4038_7499;623_21687": "An error has occurred, please try again or contact the administrator.",
  "4038_7505": "No data",
  "4038_7496": "Service User",
  "I4038_7506;611_93387": "The Organization has been successfully updated.",
  "4038_7497": "Service Provider 2B",
  "I4038_7305;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I4038_7304;623_21687": "Provided token is invalid",
  "4038_7290": "Notification",
  "4038_7297": "Female",
  "4038_7303": "No data",
  "4038_7295": "Male",
  "4038_7291": "Your profile has been successfully updated.",
  "4030_3602": "Are your sure to active wrapper group?",
  "I4030_3502;645_18159;30_32137": "Cancel",
  "I4030_3501;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I4030_3498;611_93387": "The Wrapper Group has been successfully updated.",
  "4030_3618": "Confirm",
  "I4030_3502;645_18155": "Warning",
  "4030_3641": "Notification",
  "4030_3513": "Coppy",
  "I4030_3502;645_18160;30_31931": "Confirm",
  "I4030_3499;623_21593": "Warning",
  "4030_3629": "Cancel",
  "4030_3616": "Cancel",
  "I4030_3500;623_21698": "Info",
  "4030_3631": "Confirm",
  "4030_3522": "Delete",
  "4030_3642": "Deleted successful!",
  "4030_3610": "Warning",
  "4030_3619": "Are your sure to inactive wrapper group ?",
  "4110_1746": "Make Inactive",
  "I4110_1444;611_93387": "The Wrapper Group has been successfully created.",
  "4030_3634": "Warning",
  "4030_3518": "Edit",
  "4110_1743": "Delete",
  "4110_1744": "Make Active",
  "4030_3605": "Cancel",
  "4030_3607": "Confirm",
  "4030_3508": "View",
  "4030_3622": "Warning",
  "I4030_3502;645_18156": "Are you sure you want to delete Wrapper Group?",
  "4030_3626": "Are your sure to delete wrapper group?",
  "I4110_1455;611_93387": "The Wrapper Group has been successfully deleted.",
  "4030_5337": "Apps",
  "4030_5371": "Access Logs",
  "4030_5320": "Develop Console",
  "4030_5275": "IP",
  "4030_5291": "Time Range From",
  "4030_5278": "10.10.10.10",
  "4030_5269": "Name A",
  "4030_5297": "Wrapper API",
  "4030_5403": "API Gateway",
  "4030_5318": "5",
  "4007_8593": "Please fill your App Callback URL! (https://example.com)",
  "4030_5397": "Review Access Limit",
  "4030_5358": "Traffic Control",
  "4030_5289": "Add more",
  "4030_5391": "API Testing",
  "4030_5306": "Apply To",
  "4030_5382": "Alert Rules",
  "4030_5285": "List of IP addresses will be allowed to access the API",
  "4030_5386": "Dowload SDK",
  "4030_5354": "Wraper Group",
  "4030_5271": "ACTIVE",
  "4030_5300": "Wrapper API",
  "4030_5366": "Analytics",
  "4030_5263": "Status",
  "4030_5378": "Alert",
  "4030_5310": "Add more",
  "4030_5262": "Name",
  "4030_5265": "Back",
  "4007_8591": "App Callback URL",
  "4030_5362": "Access Limit",
  "4030_5315": "Time Range",
  "4030_5293": "Time Range To",
  "4030_5280": "SINGLE",
  "4030_5284": "Allowlist",
  "4030_5405": "Access Limit",
  "4030_5350": "Wraper API",
  "4007_8592": "Callback URL",
  "4030_5325": "Setting",
  "4030_5298": "Wrapper API Group",
  "4030_5302": "Wrapper API Group",
  "4030_5330": "API Gateway",
  "4030_5323": "Project Overview",
  "4030_5276": "Type",
  "4030_5295": "Time Range From",
  "4030_5404": "/",
  "4030_5341": "AMS",
  "4030_5290": "Time Range To",
  "4038_7189": "Name",
  "4007_8713": "Analytics",
  "4007_8670": "Project Overview",
  "4007_8667": "Develop Console",
  "4038_7231": "Review Alert Rules",
  "4007_8729": "Alert Rules",
  "4007_8733": "Dowload SDK",
  "4038_7229": "Rule Condition",
  "4038_7190": "Message",
  "4038_7194": "Value",
  "4038_7240": "Alert Rules",
  "4038_7206": "STATUS CODE ",
  "4038_7214": "EQUAL",
  "4007_8672": "Setting",
  "4007_8718": "Access Logs",
  "4007_8725": "Alert",
  "4038_7226": "dung.nguyen+200@netko",
  "4038_7222": "ACTIVE",
  "4007_8677": "API Gateway",
  "4007_8697": "Wraper API",
  "4007_8705": "Traffic Control",
  "4007_8701": "Wraper Group",
  "4007_8709": "Access Limit",
  "4038_7193": "Condition",
  "4038_7195": "Status",
  "4007_8665": "5",
  "4038_7238": "API Gateway ",
  "4038_7198": "Back",
  "4038_7202": "Error",
  "4038_7191": "Type",
  "4038_7218": "500",
  "4038_7210": "AMS",
  "4007_8738": "API Testing",
  "4038_7204": "500",
  "4038_7239": "/ ",
  "4038_7196": "Notification",
  "4038_7192": "Resource",
  "4007_8688": "AMS",
  "4007_8684": "Apps",
  "4089_1782": "Incorrect Project code!",
  "4097_1823": "Please choose the Service!",
  "4097_1825": "Enter type",
  "4097_1829": "Enter Value",
  "4091_1785": "Enter App Code",
  "4094_1811": "Deactive successful!",
  "4070_1517": "Project name (a-z 0-9 and space)",
  "4094_1810": "Active successful!",
  "4060_1493": "Cancel",
  "4097_1821": "Relative Path",
  "4060_1499": "Warning",
  "4097_1820": "Please choose the Relative Path!",
  "4097_1822": "Please fill the Type!",
  "4068_1515": "Success",
  "4072_1519": "Please fill your Project Name",
  "4120_2297": "IP address is invalid",
  "4119_2295": "Please fill the status!",
  "4072_1521": "Please fill Project Number",
  "4089_1781": "Please fill your Project Code",
  "4096_1819": "Consumer Endpoint is valid (a-z,0-9,/,-)",
  "4121_2321": "Time range to than time range from",
  "4121_2319": "Please Enter IP Address",
  "4066_1513": "Phone is invalid!",
  "4060_1495": "Confirm",
  "4097_1827": "Param name is valid (a-z,0-9,-)!",
  "4097_1828": "Enter Condition",
  "4072_1520": "Please fill your Project Code",
  "4089_1784": "Incorrect Project Code",
  "4097_1826": "Please fill the Param Name",
  "4119_2296": "Please fill the Prefilter",
  "4072_1522": "Please fill your environment",
  "4076_1736": "Deleted successful!",
  "4089_1783": "Delete Project Success",
  "4132_2509": "Enter status!",
  "4121_2323": "Enter prefilter",
  "4121_2320": "Enter time range",
  "4066_1514": "Postcode is wrong!",
  "4091_1787": "Incorrect App code!",
  "4070_1516": "Project name is required",
  "4072_1708": "Delete",
  "4072_1634": "Create App",
  "4072_1635": "Preview",
  "4072_1671": "Name",
  "4072_1676": "App Icon",
  "4072_1636": "-",
  "4072_1697": "Enter your description",
  "4072_1654": "API Gateway",
  "4072_1700": "Upload",
  "4072_1679": "Back",
  "4072_1675": "Status",
  "4072_1644": "Develop Console",
  "4072_1677": "Description",
  "4072_1666": "AMS",
  "4072_1689": "Web",
  "4072_1693": "Active",
  "4072_1647": "Project Overview",
  "4072_1673": "Callback URL",
  "4072_1685": "om-admin-develop-console-app-22",
  "4072_1672": "App Code",
  "4072_1649": "Setting",
  "4072_1704": "Security",
  "4072_1683": "App 22",
  "4072_1681": "Create",
  "4072_1674": "Platform",
  "4072_1642": "5",
  "4072_1687": "https://...",
  "4072_1662": "Apps",
  "4105_1973": "Analytics",
  "4105_1836": "Description",
  "4105_1905": "Add more",
  "4105_1989": "Alert Rules",
  "4105_1838": "Back",
  "4105_2011": "API Gateway",
  "4105_2013": "Wrapper API",
  "4105_1874": "Enter description.",
  "4105_1842": "Sports 22",
  "4105_1913": "Filtering",
  "4105_1978": "Access Logs",
  "4105_1948": "AMS",
  "4105_1985": "Alert",
  "4105_1832": "Consumer Endpoint",
  "4105_1852": "POST",
  "4105_1901": "Add more",
  "4105_1870": "Group",
  "4105_1908": "-",
  "4105_1965": "Traffic Control",
  "4105_1916": "Resource",
  "4105_1886": "Target",
  "4105_1909": "Filtering",
  "4105_2012": "/",
  "4105_1833": "Method",
  "4105_1844": "/sport/country/import",
  "4105_1860": "DELETE",
  "4105_1848": "GET",
  "4105_2007": "Copy Wrapper API",
  "4105_1944": "Apps",
  "4105_1895": "Multiple",
  "4105_1957": "Wraper API",
  "4105_1912": "-",
  "4105_1927": "Develop Console",
  "4105_1884": "Enable IDM Auth",
  "4105_1937": "API Gateway",
  "4105_1891": "Single",
  "4105_1907": "Pre",
  "4105_1831": "Name",
  "4105_1969": "Access Limit",
  "4105_1998": "API Testing",
  "4105_1915": "Service",
  "4105_1925": "5",
  "4105_1866": "ACTIVE",
  "4105_1918": "Enter service...",
  "4105_1993": "Dowload SDK",
  "4105_1961": "Wraper Group",
  "4105_1856": "PUT",
  "4105_1914": "Service",
  "4105_1878": "Relative",
  "4105_1932": "Setting",
  "4105_1922": "/sport/football/country",
  "4105_1834": "Status",
  "4105_1930": "Project Overview",
  "4105_1911": "Post",
  "4105_1840": "Make A Copy",
  "4105_1835": "Groups",
  "4110_1464": "Name",
  "4110_1489": "5",
  "4110_1501": "API Gateway",
  "4110_1571": "Copy Wrapper Group",
  "4110_1468": "Back",
  "4110_1512": "AMS",
  "4110_1537": "Analytics",
  "4110_1486": "Enter description.",
  "4110_1557": "Dowload SDK",
  "4110_1470": "Make A Copy",
  "4110_1466": "Description",
  "4110_1508": "Apps",
  "4110_1533": "Access Limit",
  "4110_1576": "/",
  "4110_1476": "Sports 22",
  "4110_1472": "Wrapper Group 33",
  "4110_1465": "Wrappers",
  "4110_1529": "Traffic Control",
  "4110_1553": "Alert Rules",
  "4110_1480": "Sports 22",
  "4110_1542": "Access Logs",
  "4110_1521": "Wraper API",
  "4110_1496": "Setting",
  "4110_1577": "Wrapper Group",
  "4110_1562": "API Testing",
  "4110_1525": "Wraper Group",
  "4110_1494": "Project Overview",
  "4110_1491": "Develop Console",
  "4110_1549": "Alert",
  "4110_1575": "API Gateway",
  "4110_1678": "Alert Rules",
  "4110_1646": "Wraper API",
  "4110_1703": "Wrapper Group",
  "4110_1611": "Enter description.",
  "4110_1667": "Access Logs",
  "4110_1723": "Preview ",
  "4110_1697": "Create Wrapper Group",
  "4110_1702": "/",
  "4110_1626": "API Gateway",
  "4110_1637": "AMS",
  "4110_1650": "Wraper Group",
  "4110_1674": "Alert",
  "4110_1687": "API Testing",
  "4110_1598": "Wrappers",
  "4110_1614": "5",
  "4110_1619": "Project Overview",
  "4110_1633": "Apps",
  "4110_1658": "Access Limit",
  "4110_1603": "Create",
  "4110_1692": "Please fill the Name",
  "4110_1599": "Description",
  "4110_1601": "Back",
  "4110_1607": "Enter Wrapper",
  "4110_1597": "Name",
  "4110_1605": "Enter name",
  "4110_1621": "Setting",
  "4110_1682": "Dowload SDK",
  "4110_1662": "Analytics",
  "4110_1701": "API Gateway",
  "4110_1654": "Traffic Control",
  "4110_1616": "Develop Console",
  "4111_1836": "API Gateway",
  "4111_1817": "1",
  "4111_1815": "Enter Burst Capacity",
  "4111_1847": "AMS",
  "4111_1856": "Wraper API",
  "4111_1910": "Create Traffic Control",
  "4111_1904": "Please fill the Burst Capacity",
  "4111_1884": "Alert",
  "4111_1809": "Create",
  "4111_1813": "Enter Replenish Rate",
  "4111_1903": "Please fill the Rate Limit",
  "4111_1914": "API Gateway",
  "4111_1905": "Please choose Wrapper API",
  "4111_1868": "Access Limit",
  "4111_1864": "Traffic Control",
  "4111_1826": "Develop Console",
  "4111_1802": "Replenish Rate",
  "4111_1860": "Wraper Group",
  "4111_1803": "Burst Capacity",
  "4111_1819": "Enter Wrapper",
  "4111_1935": "Preview",
  "4111_1872": "Analytics",
  "4111_1801": "Name",
  "4111_1831": "Setting",
  "4111_1807": "Back",
  "4111_1805": "Wrapper API",
  "4111_1916": "Traffic Control",
  "4111_1915": "/",
  "4111_1824": "5",
  "4111_1811": "Enter name",
  "4111_1888": "Alert Rules",
  "4111_1897": "API Testing",
  "4111_1877": "Access Logs",
  "4111_1804": "Requested Tokens",
  "4111_1892": "Dowload SDK",
  "4111_1843": "Apps",
  "4111_1902": "Please fill the Name",
  "4111_1829": "Project Overview",
  "4114_2084": "Please fill your App Callback URL! (https://example.com)",
  "4114_2121": "Wrapper API Group",
  "4114_2116": "Time Range To",
  "4114_2090": "Create",
  "4114_2118": "Time Range From",
  "4114_2227": "Access Limit",
  "4114_2146": "Project Overview",
  "4114_2160": "Apps",
  "4114_2085": "Name",
  "4114_2099": "Type",
  "4114_2181": "Traffic Control",
  "4114_2177": "Wraper Group",
  "4114_2201": "Alert",
  "4114_2120": "Wrapper API",
  "4114_2112": "Add more",
  "4114_2209": "Dowload SDK",
  "4114_2143": "Develop Console",
  "4114_2214": "API Testing",
  "4114_2088": "Back",
  "4114_2138": "Time Range",
  "4114_2153": "API Gateway",
  "4114_2082": "App Callback URL",
  "4114_2194": "Access Logs",
  "4114_2114": "Time Range From",
  "4114_2086": "Status",
  "4114_2113": "Time Range To",
  "4114_2107": "Allowlist",
  "4114_2185": "Access Limit",
  "4114_2226": "/",
  "4114_2220": "Preview",
  "4114_2141": "5",
  "4114_2148": "Setting",
  "4114_2221": "Access Limit",
  "4114_2133": "Add more",
  "4114_2103": "SINGLE",
  "4114_2205": "Alert Rules",
  "4114_2092": "Name A",
  "4114_2173": "Wraper API",
  "4114_2094": "ACTIVE",
  "4114_2225": "API Gateway",
  "4114_2083": "Callback URL",
  "4114_2101": "10.10.10.10",
  "4114_2098": "IP",
  "4114_2108": "List of IP addresses will be allowed to access the API",
  "4114_2189": "Analytics",
  "4114_2164": "AMS",
  "4114_2123": "Wrapper API",
  "4114_2125": "Wrapper API Group",
  "4114_2129": "Apply To",
  "4131_2483": "Create Alert Rules",
  "4131_2420": "Alert",
  "4131_2445": "Condition",
  "4131_2470": "500",
  "4131_2442": "Message",
  "4131_2392": "Wraper API",
  "4131_2462": "AMS",
  "4131_2452": "Create",
  "4131_2454": "Error",
  "4131_2362": "Develop Console",
  "4131_2400": "Traffic Control",
  "4131_2481": "Rule Condition",
  "4131_2478": "dung.nguyen+200@netko",
  "4131_2447": "Status",
  "4131_2489": "/ ",
  "4131_2458": "STATUS CODE ",
  "4131_2396": "Wraper Group",
  "4131_2372": "API Gateway",
  "4131_2367": "Setting",
  "4131_2474": "ACTIVE",
  "4131_2365": "Project Overview",
  "4131_2379": "Apps",
  "4131_2383": "AMS",
  "4131_2428": "Dowload SDK",
  "4131_2404": "Access Limit",
  "4131_2443": "Type",
  "4131_2446": "Value",
  "4131_2441": "Name",
  "4131_2466": "EQUAL",
  "4131_2360": "5",
  "4131_2456": "500",
  "4131_2448": "Notification",
  "4131_2484": "Preview",
  "4131_2488": "API Gateway ",
  "4131_2433": "API Testing",
  "4131_2413": "Access Logs",
  "4131_2444": "Resource",
  "4131_2408": "Analytics",
  "4131_2490": "Alert Rules",
  "4131_2424": "Alert Rules",
  "4131_2450": "Back",
  "217_4561": "Country Code"
}

export default localeDefault

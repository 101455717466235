import CPClient from "../client/ClientControlPlane"
import version from "../version"

function getDetailApproval(id) {
  return CPClient("root_approval.approval_path_detail", "detail").get(
    `${version}/internal/approvals/${id}/`
  )
}
function updateApprovalConfirm(id, data) {
  return CPClient("root_approval.approval_path_update").put(
    `${version}/internal/approvals/${id}/change_approval_status/`,
    data
  )
}

const api = {
  getDetailApproval,
  updateApprovalConfirm
}
export default api

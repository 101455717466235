import { Select } from "antd"
import { apiAms } from "api"
import convertTransalte from "lib/function"
import { forwardRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { changeLanguage } from "utils/i18n"

const DropdownLanguage = forwardRef(({ onChange, value, setDisable }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState()
  const [rows, setRows] = useState([])

  async function onChangeLanguage(v) {
    setDisable(true)
    setLoading(true)

    await changeLanguage(v)

    setLoading(false)
    setDisable(false)
    onChange(v)
  }

  useEffect(() => {
    async function fetch() {
      const language = localStorage.getItem("language")
      if (language) {
        setRows(convertTransalte())
      } else {
        let translates = {
          en: "English"
        }
        let res = await apiAms.getLanguages()
        localStorage.setItem("language", JSON.stringify(res?.languages))
        translates = res?.languages
        let __rows = Object.keys(translates).map((i) => {
          return {
            label: translates[i],
            value: i
          }
        })
        setRows(__rows)
      }
    }
    fetch()
  }, [])

  return (
    <div className="w-40">
      <Select
        className="w-40"
        onChange={onChangeLanguage}
        loading={loading}
        placeholder={t("2092_932")}
        showSearch
        value={value}
        options={rows}
      />
    </div>
  )
})

export default DropdownLanguage

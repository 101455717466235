import { Form, Input, Button } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { apiUser } from "api"
import { setCookieAuth } from "lib/cookie"

// LoginForm component for handling user authentication
const LoginForm = () => {
  // Function to handle form submission
  const onFinish = async (values) => {
    try {
      // Call the API to perform user login
      const { data } = await apiUser.login(values)

      // Extract access_token and refresh_token from the API response
      const { access_token, refresh_token } = data

      // Set authentication cookies with obtained tokens
      setCookieAuth({ access_token, refresh_token })

      // Reload the window to apply authentication changes
      window.location.reload()
    } catch (error) {}
  }

  // Function to simulate login for demo purposes
  function onLogin() {
    // Default login credentials for demo
    const values = {
      username: "datorg@gmail.com",
      password: "123456"
    }

    // Trigger form submission with demo credentials
    onFinish(values)
  }

  // Check if the application is in demo mode based on the URL
  const isDemo = !window.location.origin.includes("com")

  return (
    <div className="flex bg-gray-50 dark:bg-gray-900 flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div className="w-full bg-white p-6 rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700">
        {/* Ant Design Form for user login */}
        <Form name="normal_login" initialValues={{ remember: true }} onFinish={onFinish}>
          {/* Input field for username */}
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please enter your username!" }]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>

          {/* Input field for password */}
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              className="mb-8"
              placeholder="Password"
            />
          </Form.Item>

          {/* Demo login button (visible in demo mode) */}
          {isDemo && (
            <div className="flex justify-center p-2 gap-2">
              <Button onClick={onLogin}>Demo Login</Button>
            </div>
          )}

          {/* Submit button for the login form */}
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" className="w-full">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default LoginForm

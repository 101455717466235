import Client from "../client/ClientControlPlane"
import version from "../version"
const resource = version + "/internal/wrapper"

function gets(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/`, { params })
}
function get(id) {
  return Client("root_wrapper.wrapper_path_detail").get(`${resource}/${id}/`)
}

const api = {
  gets,
  get
}
export default api

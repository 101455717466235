import Client from "../client/ClientCommon"
import version from "../version"
const resource = version + "/activity/logs"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(params) {
  return Client.get(`${version}/activity/detail/`, { params })
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}

const api = {
  gets,
  get,
  create,
  update
}
export default api

import Client from "../client/ClientGateway"
import version from "../version"
const resource = version + "/internal/analysis"

function getListAnalytics(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/count`, { params })
}
function getServicesCount(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/api-visit-count`, { params })
}
function getSummary(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/reporting-summary`, { params })
}
function getTimeSpend(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/reporting-time-spent`, {
    params
  })
}
function getRequestCount(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/requests-count`, {
    params
  })
}

function getAvgDurationPerApi(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/avg-duration-per-api`, {
    params
  })
}

function getAvgDurationPerHour(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/avg-duration-per-hour`, {
    params
  })
}

function getAvgDurationPerMinute(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/avg-duration-per-minute`, {
    params
  })
}

function getAvgReponseTime(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/avg-response-time`, {
    params
  })
}

const api = {
  getListAnalytics,
  getServicesCount,
  getSummary,
  getTimeSpend,
  getRequestCount,
  getAvgDurationPerApi,
  getAvgDurationPerHour,
  getAvgReponseTime,
  getAvgDurationPerMinute
}
export default api

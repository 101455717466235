import Cookies from "js-cookie"
var parts = window.location.hostname.split(".")
var sndleveldomain = parts.slice(-2).join(".")
const config = {
  domain: sndleveldomain,
  expires: 3
}
export function setCookieAuth(auth) {
  let authSave = {}
  if (auth && Object.keys(auth)?.length) {
    authSave = auth
  }
  Cookies.set("auth", JSON.stringify(authSave), config)
}
export function getCookieAuth() {
  const v = Cookies.get("auth", config)
  if (v) return JSON.parse(v)
  return {}
}

export function removeCookies() {
  // Cookies.remove("userInfo", config)
  Cookies.remove("auth", config)
}

export function setI18nextLng(i18nextLng) {
  Cookies.set("i18nextLng", i18nextLng, config)
}
export function getI18nextLng() {
  return Cookies.get("i18nextLng", config)
}

import Client from "../client/ClientGateway"
import version from "../version"
const resource = version + "/internal/events"

function gets(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/alert`, { params })
}

function updateStatus(id, data) {
  return Client("root_wrapper.wrapper_path_update").put(`${resource}/${id}/change-status`, data)
}
function updateStatusMutil(data) {
  return Client("root_wrapper.wrapper_path_update").put(`${resource}/change-status/multiple`, data)
}

// function getTrafficControl(id) {
//   return Client.get(`${resource}/${id}`)
// }
// function create(data) {
//   return Client.post(`${resource}`, data)
// }
// function update(id, data) {
//   return Client.put(`${resource}/${id}`, data)
// }
// function remove(id) {
//   return Client.delete(`${resource}/${id}`)
// }

const api = {
  gets,
  updateStatus,
  updateStatusMutil
}
export default api

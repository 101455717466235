import Client from "../client/ClientControlIDM"
import version from "../version"

const resource = version + "/internal/user/view"

const login = (data) => {
  return Client("root_admin_user.admin_user_path_create").post(`/api/v1/account/login/`, data)
}
const logout = (data) => {
  return Client("root_admin_user.admin_user_path_create").post(`/api/v1/account/logout/`, data)
}
function getUserInfo(data) {
  return Client("root_admin_user.admin_user_path_view").get(
    `${version}/account/view/profile/`,
    data
  )
}
function gets(params) {
  return Client("root_admin_user.admin_user_path_view").get(`${resource}/`, { params })
}
function getOrganizations() {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/organization/view/`)
}
const getPermissions = (params) => {
  return Client("root_admin_user.admin_user_path_view").get(`/api/v1/account/permissions/`, {
    params
  })
}
const getPersonalDataHistory = (params) => {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/personal-data/list/`, {
    params
  })
}
function getOwnSettingAccount(params) {
  return Client("root_admin_user.admin_user_path_view").get(`${version}/org-settings/`, { params })
}
function updateProfile(data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/update/profile/`,
    data
  )
}
function changePassword(data) {
  return Client("root_admin_user.admin_user_path_update").put(
    `${version}/account/change-password/`,
    data
  )
}

const api = {
  login,
  logout,
  getUserInfo,
  gets,
  getOrganizations,
  getPermissions,
  getPersonalDataHistory,
  getOwnSettingAccount,
  updateProfile,
  changePassword
}

export default api

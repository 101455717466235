export const RESOURCE_KEY = {
  ROOT_GW_ORGANIZATION: "root_gw_organization",
  ROOT_WRAPPER_MASTER_DATA: "root_wrapper_master_data",
  ROOT_WRAPPER: "root_wrapper",
  ROOT_WRAPPER_GROUP_MASTER_DATA: "root_wrapper_group_master_data",
  ROOT_WRAPPER_GROUP: "root_wrapper_group",
  ROOT_ALERT_RULE_MASTER_DATA: "root_alert_rule_master_data",
  ROOT_ALERT_RULE: "root_alert_rule",
  ROOT_USER: "root_user",
  ROOT_IDM_ORGANIZATION: "root_idm_organization",
  ROOT_POLICY: "root_policy",
  ROOT_ROLE: "root_role",
  ROOT_IDENTITY_PROVIDER: "root_identity_provider",
  ROOT_OAUTH_CLIENT: "root_oauth_client",
  ROOT_APPLICATION: "root_application",
  ROOT_VEHICLE: "root_vehicle",
  ROOT_RESOURCE: "root_resource",
  ROOT_DESIGN_FILE: "root_design_file",
  ROOT_TRANSLATION_VERSION: "root_translation_version",
  ROOT_UI_MANAGEMENT: "root_ui_management",
  ROOT_TRANSLATION_TASK: "root_translation_task",
  ROOT_TRANSLATION_ELEMENT: "root_translation_element",
  ROOT_CLIENT_DICTIONARY: "root_client_dictionary",
  ROOT_SYSTEM_ERROR: "root_system_error",
  ROOT_ALL_RESOURCES: "root_all_resources",
  ROOT_PROJECT_RESOURCE: "root_project_resource",
  ROOT_SERVICE: "root_service",
  ROOT_APPROVAL_SETTING: "root_approval_setting",
  ROOT_APPROVAL: "root_approval",
  ROOT_DASHBOARD: "root_dashboard_idm",
  ROOT_CLIENT_REPORT: "root_ac_report"
}

export const SERVICE_CODE = {
  API_CONTROL_PLANE: "api-control-plane",
  API_OM_API_GATEWAY: "api-om-api-gateway",
  API_IDM: "api-idm",
  API_AMS: "api-ams",
  API_COMMOM: "api-common",
  API_NOTI_INAPP: "api-notification-inapp"
}

export const OBJECT_PAGE = {
  WRAPPER: "wrapper",
  WRAPPER_GROUP: "wrapper_groups",
  APP: "applications",
  PROJECT: "project",
  TRAFFIC_CONTROL: "traffic_control",
  ACCESS_LIMIT: "whitelist_ip",
  MONITORING: "monitoring",
  ANALYTICS: "analysis",
  ACCESS_LOG: "access_logs",
  ALERT: "alert",
  ALERT_RULE: "alert_rule",
  DOWNLOAD_SDK: "sdk_download",
  TERMS: "terms",
  USER_GROUP_PROJECTS: "user_group_projects",
  PROJECT_REQUEST: "project_request",
  PROJECT_FOLDERS: "project_folders",
  DESIGN_FILES: "design_files",
  ALL: "all"
}

export const SERVICE_TYPE = [
  {
    label: "INTERNAL",
    value: "INTERNAL"
  },
  {
    label: "EXTERNAL",
    value: "EXTERNAL"
  }
]

export const ENVIRONMENT = [
  {
    label: "Development",
    value: "development"
  },
  {
    label: "Testing",
    value: "testing"
  },
  {
    label: "Staging",
    value: "staging"
  },
  {
    label: "Production",
    value: "production"
  }
]
export const configLocale = {
  designFileId: process.env.REACT_APP_TRANSLATE_DESIGN_FILE,
  projectId: process.env.REACT_APP_TRANSLATE_PROJECT_ID
}

export const PLATFORM = [
  {
    label: "Web",
    value: "web"
  },
  {
    label: "Android",
    value: "android"
  },
  {
    label: "IOS",
    value: "ios"
  }
]

export const Approval_Status = {
  approved: "approved",
  rejected: "rejected",
  in_process: "in_process"
}

export const TYPE_BLOCK_NOTI = {
  APPROVAL: 1,
  NEWS: 2,
  MARKETING: 3,
  PROMOTION: 4,
  EVENT: 5,
  TASK: 6
}

export const ACTION_LOGS = [
  {
    label: "Create",
    value: "create"
  },
  {
    label: "Update",
    value: "update"
  },
  {
    label: "Delete",
    value: "delete"
  }
]

export const GENDER_TYPE = {
  MALE: "MALE",
  FEMALE: "FEMALE"
}

export const ORGANIZATION_SETTING_TYPE = {
  adminPassword: 1,
  enduserPassword: 2,
  accessLimit: 3,
  otp: 4
}

export const Setting_Password_Policy = {
  allow_special_character: "allow_special_character",
  alphabetic_letter_required: "alphabetic_letter_required",
  lowercase_letter_required: "lowercase_letter_required",
  uppercase_letter_required: "uppercase_letter_required",
  numbers_required: "numbers_required",
  password_min_length: "password_min_length",
  password_max_length: "password_max_length",
  allowed_character_checked: "allowed_character_checked",
  password_enforce_history: "password_enforce_history",
  special_symbol_required: "special_symbol_required"
}
export const ENUMS_CONFIG = {
  alert_rule_status: [
    {
      label: "ACTIVE",
      value: "ACTIVE"
    },
    {
      label: "INACTIVE",
      value: "INACTIVE"
    }
  ],
  filter_condition: [
    {
      label: "EQUAL",
      value: "EQUAL"
    }
  ],
  filter_type: [
    {
      label: "HEADER",
      value: "HEADER"
    },
    {
      label: "BODY",
      value: "BODY"
    }
  ],
  method: [
    {
      label: "GET",
      value: "GET"
    },
    {
      label: "POST",
      value: "POST"
    },
    {
      label: "PUT",
      value: "PUT"
    },
    {
      label: "DELETE",
      value: "DELETE"
    }
  ],
  override_type: [
    {
      label: "OVERRIDE",
      value: "OVERRIDE"
    },
    {
      label: "RENAME",
      value: "RENAME"
    }
  ],
  rule_type: [
    {
      label: "STATUS CODE",
      value: "STATUS_CODE"
    },
    {
      label: "HTTP MESSAGE",
      value: "HTTP_MESSAGE"
    }
  ],
  whitelist_ip_status: [
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "InActive",
      value: "INACTIVE"
    }
  ],
  whitelist_ip_type: [
    {
      label: "SINGLE",
      value: "SINGLE"
    },
    {
      label: "RANGE",
      value: "RANGE"
    }
  ],
  wrapper_group_status: [
    {
      label: "PENDING",
      value: "PENDING"
    },
    {
      label: "APPROVED",
      value: "APPROVED"
    },
    {
      label: "UNAPPROVED",
      value: "UNAPPROVED"
    }
  ],
  wrapper_type: [
    {
      label: "SINGLE",
      value: "SINGLE"
    },
    {
      label: "MULTIPLE",
      value: "MULTIPLE"
    }
  ],
  service_type: [
    {
      label: "INTERNAL",
      value: "INTERNAL"
    },
    {
      label: "EXTERNAL",
      value: "EXTERNAL"
    }
  ],
  access_log_levels: [
    {
      label: "4038_6429",
      value: null
    },
    {
      label: "INFO",
      value: "INFO"
    },
    {
      label: "WARN",
      value: "WARN"
    },
    {
      label: "ERROR",
      value: "ERROR"
    },
    {
      label: "FATAL",
      value: "FATAL"
    }
  ],
  application_platforms: [
    {
      label: "Web",
      value: "web"
    },
    {
      label: "Android",
      value: "android"
    },
    {
      label: "IOS",
      value: "ios"
    }
  ],
  auth_methods: [
    {
      label: "API Key",
      value: "api_key"
    },
    {
      label: "oauth2",
      value: "oauth2"
    },
    {
      label: "Basic Auth",
      value: "base_auth"
    },
    {
      label: "OICD",
      value: "oicd"
    }
  ],
  project_environments: [
    {
      label: "Development",
      value: "development"
    },
    {
      label: "Testing",
      value: "testing"
    },
    {
      label: "Staging",
      value: "staging"
    },
    {
      label: "Production",
      value: "production"
    }
  ],
  protocol_services: [
    {
      label: "rest",
      value: "rest"
    },
    {
      label: "MQTT",
      value: "mqtt"
    },
    {
      label: "WebRTC",
      value: "webrtc"
    }
  ],
  service_auth_methods: [
    {
      label: "API Key",
      value: "api_key"
    },
    {
      label: "Basic Auth",
      value: "basic_auth"
    },
    {
      label: "None",
      value: "none"
    }
  ],
  service_protocols: [
    {
      label: "HTTPS",
      value: "https"
    },
    {
      label: "GRPC",
      value: "grpc"
    },
    {
      label: "MQTT",
      value: "mqtt"
    },
    {
      label: "WEBSOCKET",
      value: "websocket"
    }
  ],
  service_types: [
    {
      label: "common",
      value: "common"
    },
    {
      label: "custom",
      value: "custom"
    },
    {
      label: "external",
      value: "external"
    }
  ],
  status_services: [
    {
      label: "active",
      value: "active"
    },
    {
      label: "inactive",
      value: "inactive"
    }
  ],
  approval_status: [
    {
      label: "In Process",
      value: "in_process"
    },
    {
      label: "Approved",
      value: "approved"
    },
    {
      label: "Rejected",
      value: "rejected"
    }
  ],
  approval_setting_methods: [
    {
      label: "Create",
      value: "create"
    },
    {
      label: "Update",
      value: "update"
    },
    {
      label: "Delete",
      value: "delete"
    }
  ],
  oauth_type: [
    {
      label: "OAUTH2",
      value: 1
    },
    {
      label: "ODIC",
      value: 2
    }
  ],
  organization_types: [
    {
      label: "Tenant",
      value: 1
    },
    {
      label: "Service Provider",
      value: 2
    },
    {
      label: "Hybrid",
      value: 3
    }
  ],
  add_to: [
    {
      label: "Header",
      value: "header"
    },
    {
      label: "Query Params",
      value: "query_params"
    }
  ],
  type_services: [
    {
      label: "external",
      value: "external"
    },
    {
      label: "internal",
      value: "internal"
    }
  ],
  doc_method: [
    {
      label: "GET",
      value: "GET"
    },
    {
      label: "POST",
      value: "POST"
    },
    {
      label: "PUT",
      value: "PUT"
    },
    {
      label: "PATCH",
      value: "PATCH"
    },
    {
      label: "DELETE",
      value: "DELETE"
    }
  ]
}

export const PHONE_COUNTRY = {
  thaiLand: {
    code: "+66",
    length: 9
  },
  southAfrica: {
    code: "+27",
    length: 9
  },
  japan: {
    code: "+81",
    length: 10
  },
  poland: {
    code: "+590",
    length: 9
  }
}

export const DEFAULT_VERSION = "0.0.0"

export const SendStatus = {
  DRAFT: 0,
  PUBLISH: 1
}

export const SOURCE_LANGUAGE = [
  { label: "Japanese", value: "ja" },
  { label: "English", value: "en" },
  { label: "Vietnamese", value: "vi" },
  { label: "Thai", value: "th" }
]

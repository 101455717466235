import { OBJECT_PAGE } from "lib/Const"

const menus = [
  {
    title: "1_7572",
    icon: '\n<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">\n  <path strokeLinecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />\n</svg>\n',
    tag: "project.view",
    url: "/project_overview",
    code: OBJECT_PAGE.PROJECT,
    is_special: false,
    sub_menu: []
  },
  {
    title: "1_7576",
    icon: '\n<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">\n  <path strokeLinecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />\n  <path strokeLinecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />\n</svg>\n',
    tag: "project.view",
    url: "/setting",
    code: OBJECT_PAGE.PROJECT,
    is_special: false,
    sub_menu: [
      {
        title: "1_7584",
        icon: "",
        tag: "project.view",
        url: "/overview",
        code: OBJECT_PAGE.PROJECT
      },
      {
        title: "1_7585",
        icon: "",
        tag: "project.view",
        url: "/integration",
        code: OBJECT_PAGE.PROJECT
      },
      {
        title: "1_7586",
        icon: "",
        tag: "project.view",
        url: "/user_permission",
        code: OBJECT_PAGE.USER_GROUP_PROJECTS
      },
      {
        title: "1_7587",
        icon: "",
        tag: "project.view",
        url: "/linkage_service",
        code: OBJECT_PAGE.PROJECT
      },
      {
        title: "Terms",
        url: "/terms",
        code: OBJECT_PAGE.APP,
        is_special: false,
        sub_menu: []
      },
      {
        title: "Privacy Policy",
        url: "/privacy_policy",
        code: OBJECT_PAGE.APP,
        is_special: false,
        sub_menu: []
      }
    ]
  },
  {
    title: "1_7588",
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.2859 3.42871L3.42871 3.42871L3.42871 10.2859L10.2859 10.2859L10.2859 3.42871Z" stroke="currentColor" strokeWidth="2.05714" strokeMiterlimit="10" strokeLinecap="square"/>
<path d="M17.1431 2.57126L12.8574 6.85693L17.1431 11.1426L21.4288 6.85693L17.1431 2.57126Z" stroke="currentColor" strokeWidth="2.05714" strokeMiterlimit="10" strokeLinecap="square"/>
<path d="M20.571 13.7144L13.7139 13.7144L13.7139 20.5715H20.571V13.7144Z" stroke="currentColor" strokeWidth="2.05714" strokeMiterlimit="10" strokeLinecap="square"/>
<path d="M10.2859 13.7144L3.42871 13.7144L3.42871 20.5715H10.2859L10.2859 13.7144Z" stroke="currentColor" strokeWidth="2.05714" strokeMiterlimit="10" strokeLinecap="square"/>
</svg>
`,
    tag: "application.view",
    url: "/apps",
    code: OBJECT_PAGE.APP,
    is_special: false,
    sub_menu: []
  },
  {
    title: "4063_1506",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
    <path strokeLinecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
  </svg>`,
    url: "/download_sdk",
    code: OBJECT_PAGE.DOWNLOAD_SDK,
    is_special: false,
    sub_menu: []
  },
  {
    title: "1_7599",
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.14258 17.8589C2.89971 17.8591 3.64693 17.6868 4.32761 17.3553C5.00828 17.0237 5.60453 16.5416 6.07115 15.9453C6.53786 16.5415 7.13413 17.0236 7.81478 17.3551C8.49543 17.6867 9.24261 17.859 9.99972 17.859C10.7568 17.859 11.504 17.6867 12.1847 17.3551C12.8653 17.0236 13.4616 16.5415 13.9283 15.9453C14.3949 16.5416 14.9912 17.0237 15.6718 17.3553C16.3525 17.6868 17.0997 17.8591 17.8569 17.8589" fill="white"/>
          <path d="M2.14258 17.8589C2.89971 17.8591 3.64693 17.6868 4.32761 17.3553C5.00828 17.0237 5.60453 16.5416 6.07115 15.9453C6.53786 16.5415 7.13413 17.0236 7.81478 17.3551C8.49543 17.6867 9.24261 17.859 9.99972 17.859C10.7568 17.859 11.504 17.6867 12.1847 17.3551C12.8653 17.0236 13.4616 16.5415 13.9283 15.9453C14.3949 16.5416 14.9912 17.0237 15.6718 17.3553C16.3525 17.6868 17.0997 17.8591 17.8569 17.8589" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
          <path d="M4.99972 12.8588V2.14453H2.14258V12.8588" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
          <path d="M17.8571 12.8588V2.14453H15V12.8588" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
          <path d="M5 2.85547C5 4.18155 5.52678 5.45332 6.46447 6.391C7.40215 7.32868 8.67392 7.85547 10 7.85547C11.3261 7.85547 12.5979 7.32868 13.5355 6.391C14.4732 5.45332 15 4.18155 15 2.85547" stroke="currentColor" strokeMiterlimit="10"/>
          <path d="M5 10.7148H15" stroke="currentColor" strokeMiterlimit="10"/>
          </svg>
          `,
    tag: "project.view",
    url: "/api_gateway",
    code: OBJECT_PAGE.WRAPPER,
    is_special: false,
    sub_menu: [
      {
        title: "4363_1971",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.8569 10.716V8.93025L15.5369 8.61382C15.4005 8.06929 15.1838 7.54811 14.894 7.06739L16.3112 5.2031L14.7983 3.6881L12.934 5.10525C12.4533 4.8154 11.9321 4.59874 11.3876 4.46239L11.0712 2.14453H8.92829L8.61186 4.46453C8.06734 4.60089 7.54615 4.81755 7.06544 5.10739L5.20115 3.6881L3.68615 5.2031L5.10329 7.06739C4.81345 7.54811 4.59679 8.06929 4.46044 8.61382L2.14258 8.93025V10.716" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M10 15.0006V10.7148" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M5.29492 13.9906L7.14206 12.1434V10.7148" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M14.7046 13.9906L12.8574 12.1434V10.7148" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M4.28599 16.4275C5.07497 16.4275 5.71456 15.7879 5.71456 14.9989C5.71456 14.2099 5.07497 13.5703 4.28599 13.5703C3.49701 13.5703 2.85742 14.2099 2.85742 14.9989C2.85742 15.7879 3.49701 16.4275 4.28599 16.4275Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M15.7137 16.4275C16.5027 16.4275 17.1423 15.7879 17.1423 14.9989C17.1423 14.2099 16.5027 13.5703 15.7137 13.5703C14.9247 13.5703 14.2852 14.2099 14.2852 14.9989C14.2852 15.7879 14.9247 16.4275 15.7137 16.4275Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M10.0008 17.8571C10.7898 17.8571 11.4294 17.2176 11.4294 16.4286C11.4294 15.6396 10.7898 15 10.0008 15C9.21186 15 8.57227 15.6396 8.57227 16.4286C8.57227 17.2176 9.21186 17.8571 10.0008 17.8571Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              </svg>
              `,
        permission_required: "",
        url: "/wrapper_api",
        code: OBJECT_PAGE.WRAPPER,
        is_special: false,
        sub_menu: []
      },
      {
        title: "1_7619",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.8569 10.716V8.93025L15.5369 8.61382C15.4005 8.06929 15.1838 7.54811 14.894 7.06739L16.3112 5.2031L14.7983 3.6881L12.934 5.10525C12.4533 4.8154 11.9321 4.59874 11.3876 4.46239L11.0712 2.14453H8.92829L8.61186 4.46453C8.06734 4.60089 7.54615 4.81755 7.06544 5.10739L5.20115 3.6881L3.68615 5.2031L5.10329 7.06739C4.81345 7.54811 4.59679 8.06929 4.46044 8.61382L2.14258 8.93025V10.716" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M10 15.0006V10.7148" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M5.29492 13.9906L7.14206 12.1434V10.7148" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M14.7046 13.9906L12.8574 12.1434V10.7148" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M4.28599 16.4275C5.07497 16.4275 5.71456 15.7879 5.71456 14.9989C5.71456 14.2099 5.07497 13.5703 4.28599 13.5703C3.49701 13.5703 2.85742 14.2099 2.85742 14.9989C2.85742 15.7879 3.49701 16.4275 4.28599 16.4275Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M15.7137 16.4275C16.5027 16.4275 17.1423 15.7879 17.1423 14.9989C17.1423 14.2099 16.5027 13.5703 15.7137 13.5703C14.9247 13.5703 14.2852 14.2099 14.2852 14.9989C14.2852 15.7879 14.9247 16.4275 15.7137 16.4275Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              <path d="M10.0008 17.8571C10.7898 17.8571 11.4294 17.2176 11.4294 16.4286C11.4294 15.6396 10.7898 15 10.0008 15C9.21186 15 8.57227 15.6396 8.57227 16.4286C8.57227 17.2176 9.21186 17.8571 10.0008 17.8571Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
              </svg>
              `,
        permission_required: "",
        url: "/newlands_api",
        code: OBJECT_PAGE.WRAPPER,
        is_special: false,
        sub_menu: []
      },
      {
        title: "1_7628",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4294 8.57031H14.2866V14.2846H8.57227V11.4275" fill="white"/>
        <path d="M11.4294 8.57031H14.2866V14.2846H8.57227V11.4275" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
        <path d="M11.4291 5.71484H5.71484V11.4291H11.4291V5.71484Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
        <path d="M17.8569 2.14453H2.14258V17.8588H17.8569V2.14453Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
        </svg>
        `,
        permission_required: "",
        url: "/wrapper_group",
        code: OBJECT_PAGE.WRAPPER_GROUP,
        is_special: false,
        sub_menu: []
      },
      {
        title: "1_7633",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.00028 8.57031V10.5575L2.85742 11.4275" fill="white"/>
        <path d="M5.00028 8.57031V10.5575L2.85742 11.4275" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
        <path d="M10.2852 5.71429C10.2852 5.87208 10.1572 6 9.99944 6C9.84165 6 9.71373 5.87208 9.71373 5.71429C9.71373 5.55649 9.84165 5.42857 9.99944 5.42857C10.1572 5.42857 10.2852 5.55649 10.2852 5.71429Z" fill="white" stroke="currentColor" strokeWidth="0.857143"/>
        <path d="M12.1426 9.98109V7.58681C12.5063 7.17442 12.7432 6.66585 12.8251 6.12212C12.9069 5.57839 12.8301 5.0226 12.6038 4.52145C12.3776 4.0203 12.0116 3.59507 11.5497 3.29678C11.0877 2.9985 10.5496 2.83984 9.99972 2.83984C9.44987 2.83984 8.9117 2.9985 8.44979 3.29678C7.98788 3.59507 7.62184 4.0203 7.39561 4.52145C7.16939 5.0226 7.09257 5.57839 7.17439 6.12212C7.2562 6.66585 7.49317 7.17442 7.85687 7.58681V15.0011C7.85559 15.5089 8.0352 16.0006 8.36351 16.3881C8.69182 16.7755 9.14737 17.0334 9.64853 17.1155C10.1497 17.1976 10.6637 17.0985 11.0985 16.8361C11.5332 16.5736 11.8603 16.1649 12.0212 15.6832" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
        <path d="M5.40212 3.75C3.79783 4.06786 2.62497 4.80643 2.25926 5.9C1.75926 7.39571 2.90212 9.16929 4.99926 10.56" stroke="currentColor" stroke-linejoin="round"/>
        <path d="M10.7148 12.6965C14.167 13.2229 17.1198 12.3894 17.7406 10.5294C18.1834 9.20366 17.3356 7.66152 15.6763 6.35938" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
        </svg>
        `,
        permission_required: "",
        url: "/traffic_control",
        code: OBJECT_PAGE.TRAFFIC_CONTROL,
        is_special: false,
        sub_menu: []
      },
      {
        title: "1_7638",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
        <path strokeLinecap="round" stroke-linejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
      </svg>
      `,
        permission_required: "",
        url: "/white_list_ip",
        code: OBJECT_PAGE.ACCESS_LIMIT,
        is_special: false,
        sub_menu: []
      },
      {
        title: "4063_1502",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
        <path strokeLinecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z" />
        <path strokeLinecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z" />
      </svg>
      `,
        permission_required: "",
        url: "/analytics_monitoring",
        code: OBJECT_PAGE.ANALYTICS,
        is_special: false,
        sub_menu: []
      },
      {
        title: "1_7643",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
        <path strokeLinecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
      </svg>
      `,
        permission_required: "",
        url: "/analytics_reporting",
        code: OBJECT_PAGE.ANALYTICS,
        is_special: false,
        sub_menu: []
      },
      {
        title: "1_7648",
        icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <path d="M4.99833 8.57129V10.5584L2.85547 11.4284" fill="white"/>\n    <path d="M4.99833 8.57129V10.5584L2.85547 11.4284" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>\n    <path d="M10.2852 5.71429C10.2852 5.87208 10.1572 6 9.99944 6C9.84165 6 9.71373 5.87208 9.71373 5.71429C9.71373 5.55649 9.84165 5.42857 9.99944 5.42857C10.1572 5.42857 10.2852 5.55649 10.2852 5.71429Z" fill="white" stroke="currentColor" strokeWidth="0.857143"/>\n    <path d="M12.1445 9.98012V7.58583C12.5082 7.17344 12.7452 6.66487 12.827 6.12114C12.9088 5.57742 12.832 5.02163 12.6058 4.52047C12.3796 4.01932 12.0135 3.59409 11.5516 3.29581C11.0897 2.99753 10.5515 2.83887 10.0017 2.83887C9.45183 2.83887 8.91365 2.99753 8.45174 3.29581C7.98983 3.59409 7.6238 4.01932 7.39757 4.52047C7.17134 5.02163 7.09452 5.57742 7.17634 6.12114C7.25816 6.66487 7.49513 7.17344 7.85882 7.58583V15.0001C7.85755 15.508 8.03716 15.9996 8.36546 16.3871C8.69377 16.7745 9.14932 17.0324 9.65048 17.1145C10.1516 17.1966 10.6657 17.0975 11.1004 16.8351C11.5352 16.5727 11.8623 16.164 12.0231 15.6823" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>\n    <path d="M5.40407 3.74854C3.79978 4.06639 2.62693 4.80496 2.26121 5.89854C1.76121 7.39425 2.90407 9.16782 5.00121 10.5585" stroke="currentColor" stroke-linejoin="round"/>\n    <path d="M10.7148 12.697C14.167 13.2234 17.1198 12.3899 17.7406 10.5299C18.1834 9.20415 17.3356 7.66201 15.6763 6.35986" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>\n    </svg>',
        permission_required: "",
        url: "/access_logs",
        code: OBJECT_PAGE.ACCESS_LOG,
        is_special: false,
        sub_menu: []
      },

      {
        title: "1_7653",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1426 15.7132L13.5711 17.1417L17.1426 13.5703" fill="white"/>
        <path d="M12.1426 15.7132L13.5711 17.1417L17.1426 13.5703" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
        <path d="M8.57115 17.1423H2.14258V4.28516H17.8569V9.99944" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
        <path d="M6.42773 2.14453V4.28739" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
        <path d="M13.5723 2.14453V4.28739" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
        <path d="M2.14258 7.14453H17.8569" stroke="currentColor" strokeMiterlimit="10"/>
        </svg>
        `,
        permission_required: "",
        url: "/event_alerts",
        code: OBJECT_PAGE.ALERT_RULE,
        is_special: false,
        sub_menu: []
      },
      {
        title: "1_7658",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
        <path strokeLinecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
      </svg>
      `,
        permission_required: "",
        url: "/event_alert_rule",
        code: OBJECT_PAGE.ALERT,
        is_special: false,
        sub_menu: []
      },
      {
        title: "1_7663",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
        <path strokeLinecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
      </svg>
      `,
        permission_required: "",
        url: "/doc_testing",
        code: OBJECT_PAGE.PROJECT_REQUEST,
        is_special: false,
        sub_menu: []
      }
    ]
  },
  {
    title: "1_7668",
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 2.14453V17.8588" stroke="currentColor" strokeMiterlimit="10"/>
          <path d="M2.14258 10H17.8569" stroke="currentColor" strokeMiterlimit="10"/>
          <path d="M9.99986 17.8588C12.1696 17.8588 13.9284 14.3411 13.9284 10.0017C13.9284 5.66229 12.1696 2.14453 9.99986 2.14453C7.83017 2.14453 6.07129 5.66229 6.07129 10.0017C6.07129 14.3411 7.83017 17.8588 9.99986 17.8588Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
          <path d="M9.99972 17.8588C14.3391 17.8588 17.8569 14.3411 17.8569 10.0017C17.8569 5.66229 14.3391 2.14453 9.99972 2.14453C5.66034 2.14453 2.14258 5.66229 2.14258 10.0017C2.14258 14.3411 5.66034 17.8588 9.99972 17.8588Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="square"/>
          </svg>
         `,
    tag: "ams",
    url: ``,
    code: OBJECT_PAGE.DESIGN_FILES,
    is_special: false,
    sub_menu: [
      {
        title: "1_7679",
        permission_required: "",
        icon: '<svg viewBox="64 64 896 896" focusable="false" data-icon="file-add" width="20" height="20" fill="currentColor" aria-hidden="true"><path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM544 472c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v108H372c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h108v108c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V644h108c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H544V472z"></path></svg>',
        tag: "ams",
        url: "/design-file",
        code: "project"
      },
      {
        title: "1_7682",
        permission_required: "",
        icon: '<svg viewBox="64 64 896 896" focusable="false" data-icon="book" width="20" height="20" fill="currentColor" aria-hidden="true"><path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-260 72h96v209.9L621.5 312 572 347.4V136zm220 752H232V136h280v296.9c0 3.3 1 6.6 3 9.3a15.9 15.9 0 0022.3 3.7l83.8-59.9 81.4 59.4c2.7 2 6 3.1 9.4 3.1 8.8 0 16-7.2 16-16V136h64v752z"></path></svg>',
        tag: "ams",
        url: "/dictionary",
        code: "project"
      },
      {
        title: "1_7687",
        permission_required: "",
        icon: '<svg viewBox="64 64 896 896" focusable="false" data-icon="file-done" width="20" height="20" fill="currentColor" aria-hidden="true"><path d="M688 312v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm376 116c-119.3 0-216 96.7-216 216s96.7 216 216 216 216-96.7 216-216-96.7-216-216-216zm107.5 323.5C750.8 868.2 712.6 884 672 884s-78.8-15.8-107.5-44.5C535.8 810.8 520 772.6 520 732s15.8-78.8 44.5-107.5C593.2 595.8 631.4 580 672 580s78.8 15.8 107.5 44.5C808.2 653.2 824 691.4 824 732s-15.8 78.8-44.5 107.5zM761 656h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-23.1-31.9a7.92 7.92 0 00-6.5-3.3H573c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.9-5.3.1-12.7-6.4-12.7zM440 852H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"></path></svg>',
        tag: "ams",
        url: "/tasks",
        code: "user_group_projects"
      },
      {
        title: "1_7692",
        permission_required: "",
        icon: '<svg viewBox="64 64 896 896" focusable="false" data-icon="info-circle" width="20" height="20" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path></svg>',
        tag: "ams",
        url: "/system-errors",
        code: "project"
      },
      {
        title: "1_7697",
        permission_required: "",
        icon: '<svg viewBox="64 64 896 896" focusable="false" data-icon="bar-chart" width="20" height="20" fill="currentColor" aria-hidden="true"><path d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm-600-80h56c4.4 0 8-3.6 8-8V560c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v144c0 4.4 3.6 8 8 8zm152 0h56c4.4 0 8-3.6 8-8V384c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v320c0 4.4 3.6 8 8 8zm152 0h56c4.4 0 8-3.6 8-8V462c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v242c0 4.4 3.6 8 8 8zm152 0h56c4.4 0 8-3.6 8-8V304c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v400c0 4.4 3.6 8 8 8z"></path></svg>',
        tag: "ams",
        url: "/translation-ratio",
        code: "project"
      }
    ]
  }
]

export default menus

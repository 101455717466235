import Client from "../client/ClientControlPlane"
import version from "../version"

const login = (data) => {
  return Client.post(`/api/v1/authentications/login/`, data)
}
function gets(data) {
  return Client.post(`${version}/slides`, data)
}
function create(data) {
  return Client.post(`${version}/slides/`, data)
}
function update(id, data) {
  return Client.put(`${version}/account/password/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${version}/account/password/${id}/`)
}

const api = {
  login,
  gets,
  create,
  update,
  remove
}
export default api

import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { notification } from "antd"
import { getCookieAuth, removeCookies } from "lib/cookie"
import { getBaseDataLocal, removeAuthLocal, setBaseDataLocal } from "lib/localstorage"
import { v4 as uuidv4 } from "uuid"
import { SERVICE_CODE, configLocale } from "lib/Const"
import { getI18nextLng } from "lib/cookie"

const globalId = uuidv4()
export const refreshAuthLogic = (failedRequest) =>
  axios
    .post(`${process.env.REACT_APP_IDM_URL}/api/v1/account/refresh/`, {
      refresh_token: getCookieAuth()?.refresh_token
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem("accessToken", tokenRefreshResponse.data.token)

      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.token

      return Promise.resolve()
    })
    .catch((err) => {
      if (!!err?.response?.request?.status) {
        // Clear local storage and cookies on token refresh failure
        // Redirect to login page
        const data = {
          error_message: "Session has expired. Please log in again."
        }
        showError(data)
        setTimeout(() => {
          localStorage.clear()
          removeCookies()
          window.location.href = "/login"
        }, 3000)
      }
    })

export function showError(data) {
  const languageCode = getI18nextLng()
  const amsErrorMsgs = JSON.parse(localStorage.getItem("errorMsg"))
  const messageAfterTranslate = getMessageAfterTranslate(data, languageCode, amsErrorMsgs)
  if (messageAfterTranslate) {
    notification.error({
      message: messageAfterTranslate
    })
  } else if (data && Array.isArray(data?.error_message) && data?.error_message?.length > 0) {
    data?.error_message.forEach((item) => {
      if (item.message) {
        // Display error message with specified duration
        notification.error({
          message: item.message,
          duration: 3
        })
      } else {
        // Display error message with specified duration
        notification.error({
          message: item,
          duration: 3
        })
      }
    })
  } else {
    // Display error message with specified duration
    notification.error({
      message: data?.message || data?.error_message || data?.error
    })
  }
}

export default function getInstanceAxios(baseAPI, resource, service_code) {
  const instance = axios.create({
    // withCredentials: true,
    baseURL: baseAPI
  })

  instance.interceptors.request.use(
    function (config) {
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        method: config.method,
        "Http-global-id": uuidv4(),
        "Device-Id": globalId
      }

      const oldToken = getBaseDataLocal()?.access_token
      const { access_token } = getCookieAuth()
      if (oldToken && oldToken !== access_token) {
        setBaseDataLocal({})
        window.location.href = "/login"
      }
      if (access_token) config.headers["Authorization"] = `Bearer ${access_token}`

      config.headers.resource = resource
      config.headers.service = service_code
      if (
        service_code === SERVICE_CODE.API_CONTROL_PLANE ||
        service_code === SERVICE_CODE.API_OM_API_GATEWAY
      ) {
        config.headers["project-id"] = localStorage.getItem("project_id")
      }

      if (service_code === SERVICE_CODE.API_AMS)
        config.headers["project-id"] = configLocale.projectId

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      try {
        if (response.status >= 200 && response.status < 300) return response.data
        return Promise.reject(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async function (error) {
      if (error.response) {
        const { response } = error
        if (response.status === 401) {
          removeCookies()
          removeAuthLocal()
          window.location.reload()
        }
        const data = response.data
        const languageCode = getI18nextLng()
        const amsErrorMsgs = JSON.parse(localStorage.getItem("errorMsg"))
        const messageAfterTranslate = getMessageAfterTranslate(data, languageCode, amsErrorMsgs)
        if (messageAfterTranslate) {
          notification.error({
            message: messageAfterTranslate
          })
        } else if (data && response.status !== 500) {
          if (data.error_message) {
            notification.error({
              message: convertMessageError(data.error_message),
              duration: 3
            })
            return Promise.reject(data.error_message)
          }
          if (data.message) {
            notification.error({
              message: data?.message || data?.error_message
            })
            return Promise.reject(data.error_message)
          }
          if (data && !data.message && Object.values(data).length > 0) {
            if (data.length > 0) {
              data.forEach((item) => {
                notification.error({
                  message: item,
                  duration: 3
                })
              })
            } else {
              Object.values(data).forEach((item) => {
                notification.error({
                  message: item?.length > 0 ? item[0] : "N/A",
                  duration: 3
                })
              })
            }
          } else {
            notification.error({
              message: data?.message || data?.error_message
            })
          }
        }
      }
      return Promise.reject(error)
    }
  )

  createAuthRefreshInterceptor(instance, refreshAuthLogic)
  return instance
}

function getMessageAfterTranslate(data, languageCode, amsErrorMsgs) {
  const amsErrorMsg = amsErrorMsgs?.find(
    (item) => item?.language_code === languageCode && item?.error_code === data?.error_code
  )
  return amsErrorMsg?.content || undefined
}

function convertMessageError(errors) {
  if (typeof errors === "string") return errors
  var str = []
  for (let i = 0; i < errors.length; i++) {
    let k = Object.keys(errors[i])[0]
    str.push(k + " : " + errors[i][k])
  }
  return str.map((i) => {
    return <div>{i}</div>
  })
}

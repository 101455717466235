import React, { useEffect, useRef, useState } from "react"
import { Skeleton, notification } from "antd"
import { useStore } from "components/ui"
import DrawerUpdate from "./DrawerUpdate"
import { paramsUrl } from "lib/function"
import { apiProjects } from "api"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import LayoutProjectList from "components/common/Layout/LayoutProjectList"

// Create an array for placeholder data
let __array = []
for (let i = 0; i < 8; i++) {
  __array.push(i)
}

export default function ListProject() {
  const { t } = useTranslation()
  const { setProject, orgName } = useStore()
  const navigate = useNavigate()
  const [rows, setRows] = useState(__array)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const __item = useRef(false)

  // Initialize pagination object
  const __pagination = useRef({
    page_num: 1,
    page_size: 1000,
    count: 0,
    ...paramsUrl.get()
  })

  // Fetch project data
  const fetch = async () => {
    setLoading(true)
    let _rows = []
    try {
      const { data, count } = await apiProjects.gets(__pagination.current)
      _rows = data
      __pagination.current.count = count
    } catch (e) {
    } finally {
      setRows(_rows)
      setLoading(false)
    }
  }

  // Remove a project by ID
  async function onRemove(id) {
    try {
      await apiProjects.remove(id)
      notification.success({ description: t("4089_1783") })
      fetch()
    } catch (error) {}
  }

  // Handle different actions for each project
  const actionData = (key, v) => {
    if (key === "new") {
      __item.current = false
      setVisible(true)
    }
    if (key === "edit") {
      __item.current = v
      setVisible(true)
    }
    if (key === "remove") {
      onRemove(v)
    }
  }

  function onClose(isLoad) {
    setVisible(false)
    if (isLoad) {
      fetch()
    }
  }

  // Navigate to the project overview page
  async function onClickProject(v) {
    try {
      localStorage.setItem("project_id", v)
      const { data, success } = await apiProjects.get(v)
      if (success) setProject(data)
    } catch (error) {}
    navigate("/project_overview")
  }

  // Initial fetch on component mount
  useEffect(() => {
    fetch()
  }, [])

  return (
    <LayoutProjectList>
      <section className="wapper_small p-8">
        <div className="font-bold text-4xl mb-4">
          {orgName} - {t("4056_1373")}
        </div>
        <div className="mb-8 gap-4">
          <h2 className="text-2xl font-medium mb-4">{t("2216_851")}</h2>
          <div className="grid grid-cols-12 gap-4">
            <div
              className="shadow col-span-6 md:col-span-4 lg:col-span-3 text-center p-4 bg-gray-200 rounded cursor-pointer"
              onClick={() => actionData("new", false)}
            >
              <h2 className="mb-2 flex justify-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
              </h2>
              <div className="text-xl font-medium">{t("2216_805")}</div>
            </div>

            {rows?.slice(0, 3)?.map(({ name, id }, key) => {
              return (
                <div
                  key={key}
                  className="shadow col-span-6 md:col-span-4 lg:col-span-3 p-4 bg-gray-100 rounded cursor-pointer hover:bg-gray-400"
                  onClick={() => onClickProject(id)}
                >
                  <div className="">
                    <h2 className="mb-2 line-clamp-2 text-xl">
                      {name || <Skeleton paragraph={{ rows: 1 }} />}
                    </h2>
                    <div>{id}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="">
          <h2 className="font-medium mb-4 text-2xl">{t("2216_852")}</h2>
          <div className="grid grid-cols-12 gap-4">
            {rows?.map(({ name, id }, key) => {
              return (
                <div
                  key={key}
                  onClick={() => onClickProject(id)}
                  className="shadow col-span-6 md:col-span-4 lg:col-span-3 p-4 bg-gray-100 rounded cursor-pointer hover:bg-gray-100"
                >
                  <div className="">
                    <h2 className="mb-2 line-clamp-2 text-xl">
                      {name || <Skeleton paragraph={{ rows: 1 }} />}
                    </h2>
                    <div>{id}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {/* Render the DrawerUpdate component for creating or editing projects */}
        <DrawerUpdate
          visible={visible}
          item={__item.current}
          onClose={onClose}
          count={__pagination.current.count}
        />
      </section>
    </LayoutProjectList>
  )
}

import { useState, useEffect, forwardRef, useRef } from "react"
import { Select, Spin } from "antd"
import { apiProjects } from "api"
import { useStore } from "components/ui"
import { useNavigate, useSearchParams } from "react-router-dom"

const DropdownProjects = forwardRef(({}, ref) => {
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()

  const { project, setProject } = useStore()
  const [options, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const __time = useRef()

  const fetch = async (search, id) => {
    setLoading(true)
    setRows([])
    let _rows = []
    try {
      const { data } = await apiProjects.gets({ name: search, id, page_size: 1000 })
      _rows = data.map(({ id, name }) => {
        return { value: id, label: name }
      })
    } catch (e) {
    } finally {
      setRows(_rows)
      setLoading(false)
    }
  }
  async function onChange(v, url) {
    try {
      localStorage.setItem("project_id", v)
      const { data, success } = await apiProjects.get(v)
      if (success) setProject(data)
      navigate(url ? url : "/project_overview")
    } catch (error) {}
  }

  function onChangeSearch(value) {
    if (__time.current) {
      clearTimeout(__time.current)
    }
    __time.current = setTimeout(() => {
      fetch(value)
    }, [400])
  }

  useEffect(() => {
    fetch(undefined)
    const project_id = searchParams.get("project_id")
    if (project_id) onChange(project_id, window.location.pathname)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Select
      loading={loading}
      ref={ref}
      className="w-60"
      placeholder="Project"
      allowClear
      onClear={onChangeSearch}
      filterOption={false}
      value={project?.id}
      notFoundContent={loading ? <Spin size="small" /> : "No data"}
      options={options}
      onChange={onChange}
      showSearch
      onSearch={onChangeSearch}
    />
  )
})

export default DropdownProjects

import dayjs from "dayjs"
import { AlertOutlined } from "@ant-design/icons"
import { Avatar, Badge } from "antd"

export default function BoxOther({ item }) {
  const { title, created_at, content, body, is_read } = item
  return (
    <div>
      <div className="flex gap-2">
        <Badge dot={!is_read ? true : false} offset={[-5, 1]}>
          <Avatar icon={<AlertOutlined />} />
        </Badge>

        <div>
          <div className="font-semibold line-clamp-1">{title}</div>
          <div className="line-clamp-2">{body}</div>
          <div className="text-sm text-gray-400">
            {dayjs(created_at).format("HH:mm DD-MM-YYYY")}
          </div>
        </div>
      </div>

      <div>{content}</div>
      <div></div>
    </div>
  )
}

import Client from "../client/ClientGateway"
import version from "../version"
const resource = version + "/internal/traffic-controls"

function getTrafficControls(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}`, { params })
}
function getTrafficControl(id) {
  return Client("root_wrapper.wrapper_path_detail").get(`${resource}/${id}`)
}
function create(data) {
  return Client("root_wrapper.wrapper_path_create").post(`${resource}`, data)
}
function update(id, data) {
  return Client("root_wrapper.wrapper_path_update").put(`${resource}/${id}`, data)
}
function remove(id) {
  return Client("root_wrapper.wrapper_path_delete").delete(`${resource}/${id}`)
}

const api = {
  getTrafficControls,
  getTrafficControl,
  create,
  update,
  remove
}
export default api

import Client from "../client/ClientGateway"
import version from "../version"
const resource = version + "/internal/access-logs"

function gets(params) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}`, { params })
}
function get(id) {
  return Client("root_wrapper.wrapper_path_view").get(`${resource}/${id}`)
}
// function getTrafficControl(id) {
//   return Client.get(`${resource}/${id}`)
// }
// function create(data) {
//   return Client.post(`${resource}`, data)
// }
// function update(id, data) {
//   return Client.put(`${resource}/${id}`, data)
// }
// function remove(id) {
//   return Client.delete(`${resource}/${id}`)
// }

const api = {
  gets,
  get
}
export default api

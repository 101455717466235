import { lazy } from "react"
import { OBJECT_PAGE } from "lib/Const"
// const ListProject = lazy(() => import("pages/ListProject"))
const UserProfile = lazy(() => import("pages/UserProfile"))
const Security = lazy(() => import("pages/UserProfile/Security"))
const PersonalUsageHistory = lazy(() => import("pages/UserProfile/PersonalUsageHistory"))
const Notification = lazy(() => import("pages/Notification"))
const DetailNotification = lazy(() => import("pages/Notification/DetailNotification"))
const DocsAPI = lazy(() => import("pages/DocsAPI"))
const ListApps = lazy(() => import("pages/ListApps"))
const UpdateApp = lazy(() => import("pages/ListApps/UpdateApp"))
const AppSecurity = lazy(() => import("pages/ListApps/AppSecurity"))
const AppVersion = lazy(() => import("pages/ListApps/AppVersion"))
const AppUserManuals = lazy(() => import("pages/ListApps/AppUserManuals"))
const SendNotice = lazy(() => import("pages/ListApps/SendNotice"))
const UpdateVersion = lazy(() => import("pages/ListApps/UpdateVersion"))
const TermsList = lazy(() => import("pages/Terms"))
const UpdateTerms = lazy(() => import("pages/Terms/UpdateTerms"))
const UpdateTranslatedTerms = lazy(() => import("pages/Terms/UpdateTranslatedTerms"))
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"))
const UpdatePolicy = lazy(() => import("pages/PrivacyPolicy/UpdatePolicy"))
const UpdateTranslatedPolicy = lazy(() => import("pages/PrivacyPolicy/UpdateTranslatedPolicy"))
const ProjectSettingOverview = lazy(() => import("pages/ProjectSettingOverview"))
const LinkageService = lazy(() => import("pages/LinkageService"))
const UserPermission = lazy(() => import("pages/UserPermission"))
const Integration = lazy(() => import("pages/Integration"))
const WrapperList = lazy(() => import("pages/WrapperList"))
const CreateWrapper = lazy(() => import("pages/WrapperList/CreateWrapper"))
const WrapperInternalList = lazy(() => import("pages/WrapperInternalList"))
const CreateInternalWrapper = lazy(() => import("pages/WrapperInternalList/CreateWrapper"))
// const DetailWapper = lazy(() => import("pages/WrapperList/DetailWapper"))
const WrapperGroup = lazy(() => import("pages/WrapperGroup"))
const UpdateWrapperGroup = lazy(() => import("pages/WrapperGroup/UpdateWrapperGroup"))
const TrafficControl = lazy(() => import("pages/TrafficControl"))
const UpdateTrafficControl = lazy(() => import("pages/TrafficControl/UpdateTrafficControl"))
const Monitoring = lazy(() => import("pages/Monitoring"))
const Reporting = lazy(() => import("pages/Reporting"))
const WhiteListIp = lazy(() => import("pages/WhiteListIp"))
const UpdateWhiteListIP = lazy(() => import("pages/WhiteListIp/UpdateWhiteListIP"))
const AlertRule = lazy(() => import("pages/AlertRule"))
const UpdateAlertRule = lazy(() => import("pages/AlertRule/UpdateAlertRule"))
// const DetailAlertRule = lazy(() => import("pages/AlertRule/DetailAlertRule"))
const EventAlert = lazy(() => import("pages/EventAlert"))
const ProjectDashBoard = lazy(() => import("pages/ProjectDashBoard"))
// const ActivityLogs = lazy(() => import("pages/ActivityLogs"))
// const ViewLog = lazy(() => import("pages/ActivityLogs/ViewLog"))
const AccessLogs = lazy(() => import("pages/AccessLogs"))
const ViewAccessLogs = lazy(() => import("pages/AccessLogs/ViewLog"))
const SdkDownload = lazy(() => import("pages/SdkDownload"))

//____ListPage
function pageList(__role) {
  return [
    {
      Element: UserProfile,
      path: "/profile",
      resource: OBJECT_PAGE.ALL
    },
    {
      Element: Security,
      path: "/profile/security",
      resource: OBJECT_PAGE.ALL
    },
    {
      Element: PersonalUsageHistory,
      path: "/profile/personal_data_history",
      resource: OBJECT_PAGE.ALL
    },
    {
      Element: Notification,
      path: "/notification",
      code: "NOTIFICATION_CONTROLLER",
      resource: OBJECT_PAGE.ALL
    },
    {
      Element: DetailNotification,
      path: "/notification/:id",
      code: "NOTIFICATION_CONTROLLER",
      resource: OBJECT_PAGE.ALL
    },
    {
      Element: ProjectDashBoard,
      path: "project_overview",
      code: "EXAMPLE_CONTROLLER",
      resource: OBJECT_PAGE.ALL
    },
    {
      path: "/apps",
      Element: ListApps,
      code: "APPS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/apps/action",
      Element: UpdateApp,
      code: "APPS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/apps/security",
      Element: AppSecurity,
      code: "APPS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/apps/version",
      Element: AppVersion,
      code: "APPS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/apps/version/action",
      Element: UpdateVersion,
      code: "APPS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/apps/notice",
      Element: SendNotice,
      code: "APPS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/apps/manual",
      Element: AppUserManuals,
      code: "APPS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/setting/terms",
      Element: TermsList,
      code: "TERMS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/setting/terms/action",
      Element: UpdateTerms,
      code: "TERMS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/setting/terms/action/translated",
      Element: UpdateTranslatedTerms,
      code: "TERMS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/setting/privacy_policy",
      Element: PrivacyPolicy,
      code: "APPS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/setting/privacy_policy/action",
      Element: UpdatePolicy,
      code: "APPS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "/setting/privacy_policy/action/translated",
      Element: UpdateTranslatedPolicy,
      code: "TERMS_CONTROLLER",
      resource: OBJECT_PAGE.APP
    },
    {
      path: "setting/overview",
      Element: ProjectSettingOverview,
      code: "PROJECTOVERVIEW_CONTROLLER",
      resource: OBJECT_PAGE.PROJECT
    },
    {
      path: "setting/linkage_service",
      Element: LinkageService,
      code: "PROJECTOVERVIEW_CONTROLLER",
      resource: OBJECT_PAGE.PROJECT
    },
    {
      path: "setting/user_permission",
      Element: UserPermission,
      code: "PROJECTOVERVIEW_CONTROLLER",
      resource: OBJECT_PAGE.USER_GROUP_PROJECTS
    },
    {
      path: "setting/integration",
      Element: Integration,
      code: "PROJECTOVERVIEW_CONTROLLER",
      resource: OBJECT_PAGE.PROJECT
    },
    {
      path: "api_gateway/wrapper_api",
      Element: WrapperList,
      code: "WRAPPERLIST_CONTROLLER",
      resource: OBJECT_PAGE.WRAPPER
    },
    {
      path: "api_gateway/wrapper_api/action",
      Element: CreateWrapper,
      code: "WRAPPERLIST_CONTROLLER",
      resource: OBJECT_PAGE.WRAPPER
    },
    {
      path: "api_gateway/newlands_api",
      Element: WrapperInternalList,
      code: "WRAPPERLIST_CONTROLLER",
      resource: OBJECT_PAGE.WRAPPER
    },
    {
      path: "api_gateway/newlands_api/action",
      Element: CreateInternalWrapper,
      code: "WRAPPERLIST_CONTROLLER",
      resource: OBJECT_PAGE.WRAPPER
    },
    {
      path: "api_gateway/wrapper_group",
      Element: WrapperGroup,
      code: "WRAPPERGROUP_CONTROLLER",
      resource: OBJECT_PAGE.WRAPPER_GROUP
    },
    {
      path: "api_gateway/wrapper_group/action",
      Element: UpdateWrapperGroup,
      code: "WRAPPERGROUP_CONTROLLER",
      resource: OBJECT_PAGE.WRAPPER_GROUP
    },
    {
      path: "api_gateway/traffic_control",
      Element: TrafficControl,
      code: "ACCESSCONTROL_CONTROLLER",
      resource: OBJECT_PAGE.TRAFFIC_CONTROL
    },
    {
      path: "api_gateway/traffic_control/action",
      Element: UpdateTrafficControl,
      code: "ACCESSCONTROL_CONTROLLER",
      resource: OBJECT_PAGE.TRAFFIC_CONTROL
    },
    {
      path: "api_gateway/analytics_monitoring",
      Element: Monitoring,
      code: "Analytics_CONTROLLER",
      resource: OBJECT_PAGE.ANALYTICS
    },
    {
      path: "api_gateway/analytics_reporting",
      Element: Reporting,
      code: "Reporting_CONTROLLER",
      resource: OBJECT_PAGE.ANALYTICS
    },
    {
      path: "api_gateway/white_list_ip",
      Element: WhiteListIp,
      code: "WHITELISTIP_CONTROLLER",
      resource: OBJECT_PAGE.ACCESS_LIMIT
    },
    {
      path: "api_gateway/white_list_ip/action",
      Element: UpdateWhiteListIP,
      code: "ACCESSCONTROL_CONTROLLER",
      resource: OBJECT_PAGE.ACCESS_LIMIT
    },
    {
      path: "api_gateway/event_alert_rule/action",
      Element: UpdateAlertRule,
      code: "ALERTRULE_CONTROLLER",
      resource: OBJECT_PAGE.ALERT_RULE
    },
    {
      path: "api_gateway/event_alert_rule",
      Element: AlertRule,
      code: "ALERTRULE_CONTROLLER",
      resource: OBJECT_PAGE.ALERT_RULE
    },
    {
      path: "api_gateway/event_alerts",
      Element: EventAlert,
      code: "ALERTRULE_CONTROLLER",
      resource: OBJECT_PAGE.ALERT
    },
    {
      Element: AccessLogs,
      path: "api_gateway/access_logs",
      resource: OBJECT_PAGE.ACCESS_LOG
    },
    {
      Element: ViewAccessLogs,
      path: "api_gateway/access_logs/:log_id",
      resource: OBJECT_PAGE.ACCESS_LOG
    },
    {
      Element: DocsAPI,
      path: "api_gateway/doc_testing",
      resource: OBJECT_PAGE.PROJECT_REQUEST
    }, //next_component
    // {
    //   Element: ActivityLogs,
    //   path: "/activity_logs",
    //   resource: "Activity_Logs_CONTROLLER"
    // },
    // {
    //   path: "activity_logs/:log_id",
    //   Element: ViewLog,
    //   resource: "Activity_Logs_CONTROLLER"
    // },
    {
      path: "download_sdk",
      Element: SdkDownload,
      resource: OBJECT_PAGE.DOWNLOAD_SDK
    } //next_component
  ]
}

export default pageList
